import { Dialog, DialogContent, Slide } from '@material-ui/core';
import React, { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function FormBasedDialog(props) {
    const { status, title, component } = props
    const handleClose = () => {
        status.state(false)
    }
    return (
        <Dialog
            open={status.isOpen}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent id="alert-dialog-slide-title">{title}</DialogContent>
            <DialogContent>
                {component}
            </DialogContent>
        </Dialog>
    )
}
