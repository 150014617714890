import { Box, Button, FormControl, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import { ApiHelper as ApiHelperC } from "../helpers";
import ErrorBox from "./ErrorBox";

export default function EditAppereance(props) {
    const { functional } = props
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const ApiHelper = new ApiHelperC()
    const SaveAppereance = async (e) => {
        let createData = { ...functional?.editdata }
        const { title, slider_type, redirect_type, redirect } = createData
        if(title === "" ||  title === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field title is empty`, OC: true })
        }
        if(slider_type === "" ||  slider_type === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field slider_type is empty`, OC: true })
        }
        if(redirect_type === "" ||  redirect_type === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field redirect_type is empty`, OC: true })
        }
        if(redirect === "" ||  redirect === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field redirect is empty`, OC: true })
        }
        const response = await ApiHelper.SaveEditAppereance({ title, slider_type, redirect_type, redirect }, createData.id)
        if (response && typeof response.status !== "undefined" && response.status && response.status === "success") {
            functional.state(false)
            functional.onSuccess()
            setresponse_data({ ...response_data, status: response.status, message: `Appereance ID # ${createData.id} successfully updated`, OC: true })
        } // else {
        //     functional.state(false)
        // }

    }


    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
                    <Box m={4}>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <TextField id="appereancetitle" value={functional?.editdata?.title} onChange={(e) => {
                                functional?.setEditData(oldState => {
                                    return { ...oldState, title: e.target.value }
                                })
                            }} variant="outlined" placeholder="Appereacne Title" />
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <Select
                                native
                                value={functional?.editdata?.slider_type}
                                onChange={e => {
                                    functional?.setEditData(oldState => {
                                        return { ...oldState, slider_type: e.target.value }
                                    })
                                }}
                                label="Select Slider Type"
                                inputProps={{
                                    name: 'slidertype',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" disabled value="">
                                    Choose Slider Type
                                </option>
                                {
                                    ["banner", "head_banner", "side_banner", "certificate_banner", "popular_banner"].map((e, i) => (
                                        <option key={i} aria-label="None" value={e}>
                                            {e.replaceAll("_", " ").toUpperCase()}
                                        </option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <Select
                                native
                                value={functional?.editdata?.redirect_type}
                                onChange={e => {
                                    functional?.setEditData(oldState => {
                                        return { ...oldState, redirect_type: e.target.value }
                                    })
                                }}
                                label="Select Redirect Type"
                                inputProps={{
                                    name: 'redirect',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" disabled value="">
                                    Choose Redirect Type
                                </option>
                                {
                                    ["products", "category"].map((e, i) => (
                                        <option key={i} aria-label="None" value={e}>
                                            {e.toUpperCase()}
                                        </option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <TextField id="redirect_to" value={functional?.editdata?.redirect} onChange={(e) => {
                                functional?.setEditData(oldState => {
                                    return { ...oldState, redirect: e.target.value }
                                })
                            }} variant="outlined" placeholder="Redirect To" />
                        </FormControl>
                        <FormControl className="mt-3 w-100">
                            <Button variant="outlined" className="w-100" color="primary" onClick={SaveAppereance} >Save</Button>
                        </FormControl>
                    </Box>
                </div>
            </div>
        </div >
    )
}