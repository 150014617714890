import React, { useEffect, useState, useRef } from 'react'
import { Select, Box, Typography, Divider, FormGroup, FormControl, TextField, Container, Button } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react';
import NavBar from '../../widget/NavBar'
import { ApiHelper as ApiHelperc } from '../../helpers';
import PATH from '../../helpers/Routes';
import ErrorBox from '../../functions/ErrorBox';

export default function NewPost() {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const editorRef = useRef(null);
    const [categorySelect, setcategorySelect] = useState([])
    const [featureImageUri, setfeatureImageUri] = useState("")
    // const [postInfo, setpostInfo] = useState({
    //     title: "",
    //     content: "",
    //     meta_tags: "",
    //     category: "",
    //     image: "",
    //     publish: 1,
    //     permalink: "",
    //     meta_title: "",
    //     meta_desc: ""
    // })

    const postInfoRef = useRef({
        title: "",
        content: "",
        meta_tags: "",
        category: "",
        image: "",
        publish: 1,
        permalink: "",
        meta_title: "",
        meta_desc: ""
    })
    const ApiHelper = new ApiHelperc()
    async function fetchCategory() {
        const response = await ApiHelper.fetchPostCategory()
        setcategorySelect([...response.data])
    }
    useEffect(() => {
        fetchCategory()
        return () => {
            setcategorySelect([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const HadleChangePostInput = (key, value) => {
    //     postInfo[key] = value
    //     setpostInfo({ ...postInfo })
    // }

    const fileUpload = async (image) => {
        let result = await ApiHelper.uploadImage({ image }, "blogs")
        if (result.status || result.status !== undefined) {
            if (result.status === "success") {
                setresponse_data({ ...response_data, status: result.status, message: `Image successfully uploaded`, OC: true })
            }
        }
        if (result !== "") {
            result = result.data[0]
            setfeatureImageUri(result.uri)
            // HadleChangePostInput('image', result.path)
            postInfoRef.current.image = result.path
        }
    }

    const postOnClick = async () => {
        postInfoRef.current['content'] = editorRef.current.getContent()
        /**
         * Validation
         */
        if (!postInfoRef.current.image || postInfoRef.current.image === "") {
            return setresponse_data({ ...response_data, status: "Validation Error", message: "Upload Image field is blanck", OC: true })
        }
        for (let property in postInfoRef.current) {
            if (postInfoRef.current[property] === undefined || postInfoRef.current[property] === "" || postInfoRef.current[property].length <= 0) {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field is ${property} Empty`, OC: true })
            }
        }
        console.log(postInfoRef.current)
        const result = await ApiHelper.createPost(postInfoRef.current)
        if (result.status === "success") {
            let path = PATH.post.view
            setresponse_data({ ...response_data, status: result.status, message: `New post uploaded`, OC: true, redirect_to: path })
        }
    }
    document.title = `Add New Post`
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} my={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            New Post
                        </Typography>
                        <Typography variant="subtitle1">
                            Add new post to the system.
                        </Typography>
                    </div>
                    <div>
                        <Button variant="outlined" className="ms-3" color="primary" onClick={postOnClick} >Publish</Button>
                    </div>
                </Box>
                <hr />
                <Container maxWidth="xl" className="p-0">
                    <div className="d-flex flex-column flex-md-column flex-lg-row">
                        <div className="col-12 col-lg-8 col-md-12 p-0">
                            <Box m={4}>
                                <FormGroup>
                                    <FormControl variant="outlined">
                                        <TextField id="posttitle" variant="outlined" placeholder="Post title" onChange={(e) => {postInfoRef.current.title = e.target.value}} />
                                    </FormControl>
                                    <div className="mt-4 editer-add">
                                        <Editor
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            initialValue=""
                                            apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                                content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                images_upload_handler: async (blobInfo, success, failure, _) => {
                                                    const file = blobInfo.blob()
                                                    const tinyMcResult = await ApiHelper.uploadImage({ image: file }, "blogs")
                                                    if (tinyMcResult !== "") {
                                                        success(tinyMcResult.data[0].uri)
                                                    } else {
                                                        failure('Something Went Wrong')
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                        <TextField id="metatags" onChange={(e) => {postInfoRef.current.meta_tags = e.target.value}} variant="outlined" placeholder="Meta Tags" />
                                    </FormControl>
                                </FormGroup>
                            </Box>
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <Box m={4}>
                                <div className="mb-4 text-center">
                                    <div style={{ backgroundImage: `url("${featureImageUri ?? featureImageUri}")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100%", height: 200, borderWidth: "1px", borderStyle: "dashed" }}>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <Button
                                                style={{ width: '100%' }}
                                                variant="contained"
                                                component="label"
                                                color="primary"
                                            >
                                                {"Add feature image"}
                                                <input
                                                    onChange={e => { fileUpload(e.target.files[0]) }}
                                                    type="file"
                                                    hidden
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                    <Select
                                        native
                                        onChange={(e) => {postInfoRef.current.category = e.target.value}}
                                        label="Select category"
                                        inputProps={{
                                            name: 'category',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" disabled>
                                            Choose Category
                                        </option>
                                        {typeof categorySelect !== "undefined" && categorySelect.length > 0 ? categorySelect.map(category => (
                                            <option key={category.catid ?? category.catid} aria-label={category.title ?? category.title} value={category.catid ?? category.catid}>
                                                {category.title ?? category.title}
                                            </option>
                                        )) : (<></>)}
                                    </Select>
                                </FormControl>
                                <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                    <TextField id="permalink" onChange={(e) => {postInfoRef.current.permalink = e.target.value}} variant="outlined" placeholder="Blog slug" />
                                </FormControl>
                                <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                    <TextField id="metatitle" onChange={(e) => {postInfoRef.current.meta_title = e.target.value}} variant="outlined" placeholder="Meta title" />
                                </FormControl>
                                <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                    <TextField multiline={true} rows={5} id="metadescription" onChange={(e) => {postInfoRef.current.meta_desc = e.target.value}} variant="outlined" placeholder="Meta description" />
                                </FormControl>

                            </Box>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
