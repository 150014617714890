import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import ErrorBox from '../../functions/ErrorBox';
import { ApiHelper as ApiHelperc } from '../../helpers';
import PATH from '../../helpers/Routes';
import NavBar from '../../widget/NavBar';



export default function NewProduct(props) {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const editorDesc = useRef(null);
    const editorShort_content = useRef(null);
    const editorBenifit = useRef(null);
    const editorIngrediants = useRef(null);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false)
    const [categorySelect, setcategorySelect] = useState([])
    const [featureImageUri, setfeatureImageUri] = useState([])
    const [myFeatureImage, setmyFeatureImage] = useState({})
    // const [windowSize, setWindowSize] = useState(window.innerWidth)
    // const [productInfo,setproductInfo] = useState({
    //     title: "",
    //     content: "",
    //     benifits: "",
    //     ingrediants: "",
    //     gallary: "",
    //     category: "",
    //     // pack_of: "",
    //     product_permalink: "",
    //     sku: "",
    //     sales_price: "",
    //     regular_price: "",
    //     quantity: "",
    //     discount_for_online: "",
    //     tags: "",
    //     stock: "true",
    //     suppliment_for: "male",
    //     meta_title: "",
    //     meta_description: ""
    // })
    const productInforef = useRef({
        title: "",
        content: "",
        benifits: "",
        ingrediants: "",
        gallary: "",
        category: "",
        short_content: "",
        // pack_of: "",
        product_permalink: "",
        sku: "",
        sales_price: "",
        regular_price: "",
        quantity: "",
        discount_for_online: "",
        tags: "",
        stock: "true",
        suppliment_for: "male",
        meta_title: "",
        meta_description: ""
    })
    // console.log(productInfo.title)
    const ApiHelper = new ApiHelperc()
    async function fetchCategory() {
        const response = await ApiHelper.fetchPostCategory()
        setcategorySelect([...response.data])
    }
    useEffect(() => {
        fetchCategory()
        // window.addEventListener("resize",() => setWindowSize(window.innerWidth))
        return () => {
            setcategorySelect([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const HadleChangePostInput = (key, value) => {
    //     productInfo[key] = value
    //     setproductInfo({ ...productInfo })
    // }
    const fileUpload = async (image) => {

        // let processImage = image.map(e => e)
        if (image === null) return
        let result = await ApiHelper.uploadImage(image, "product", true)
        if (result.status === "success") {
            setresponse_data({ ...response_data, status: result.status, message: result.message, OC: true })
        }
        if (result !== '') {
            result = result.data
            result = result.map(e => {
                delete e['file_name']
                return e
            })
            // console.log(result)
            setfeatureImageUri(result)
            if (Array.isArray(result) && result.length > 0) HandleSetMyFeatureImage(result[0])
            // HadleChangePostInput('feature_image', result.file_name)
        } else {
            console.log('Unknown response received from the server!')
            // console.log(result)
        }
    }
    const postOnClick = async () => {

        let gallary = featureImageUri.filter(e => e.path !== myFeatureImage.path)

        gallary = gallary.map(gal => {
            gal['type'] = "gallary";
            gal['alt'] = productInforef.current.title
            gal['caption'] = productInforef.current.title
            gal['title'] = productInforef.current.title
            //delete gal['file_name']
            return gal
        })

        //delete myFeatureImage['file_name']

        gallary = [myFeatureImage, ...gallary]
        productInforef.current.gallary = gallary
        productInforef.current.content = editorDesc.current.getContent()
        productInforef.current.benifits = editorBenifit.current.getContent()
        productInforef.current.ingrediants = editorIngrediants.current.getContent()
        productInforef.current.short_content = editorShort_content.current.getContent()

        // "name", "short_content", "short_content_hi", "content", "content_hi", "product_benefit", "product_ingrediant", "category", "sku", "gallary", "price", "regular_price", "quantity", "tags", "stock", "discount_for_online", "meta_title", "meta_description", "product_permalink"

        let creatProdutModel = {
            name: productInforef.current.title,
            product_benefit: productInforef.current.benifits,
            product_ingrediant: productInforef.current.ingrediants,
            // short_content: productInforef.current.short_content,
            price: productInforef.current.sales_price,
            ...productInforef.current
        }
        delete creatProdutModel['title']
        delete creatProdutModel['benifits']
        delete creatProdutModel['ingrediants']
        delete creatProdutModel['sales_price']
        console.log(creatProdutModel)
        // return
        if (creatProdutModel.gallary && creatProdutModel.gallary.length <= 1) {
            return setresponse_data({ ...response_data, status: "Validation Error", message: "Minimum 3 images reuired for image gallary or click select feature image and one image compulsory selecting required for feature image", OC: true })
        }
        for (let property in creatProdutModel) {
            if (creatProdutModel[property] === undefined || creatProdutModel[property] === "" || creatProdutModel[property].length <= 0) {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field ${property} is Empty`, OC: true })
            }
        }
        let result = await ApiHelper.createProduct(creatProdutModel)
        if (result.status === "success") {
            let path = PATH.product.view
            setresponse_data({ ...response_data, status: result.status, message: result.message, redirect_to: path, OC: true })
        }
    }
    const handleGalleryOpen = () => {
        setIsGalleryOpen(true)
    };

    const handleGalleryClose = () => {
        setIsGalleryOpen(false)
    };

    const HandleSetMyFeatureImage = (data) => {
        data['type'] = "main"
        data['alt'] = productInforef.current.title
        data['caption'] = productInforef.current.title
        data['title'] = productInforef.current.title
        setmyFeatureImage(data)
        handleGalleryClose()
    }

    const generateFeaturedImageContent = () => {
        const darray = []
        if (Array.isArray(featureImageUri) && featureImageUri.length > 0) {
            featureImageUri.forEach((data, index) => {
                if (index % 3 === 0) {
                    darray.push([data])
                } else {
                    const tarr = darray[darray.length - 1]
                    tarr.push(data)
                    darray.pop()
                    darray.push(tarr)
                }
            })
        }
        return darray
    }
    document.title = `Add New Product`
    return (
        <div className="container-fluid">
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} my={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            New Product
                        </Typography>
                        <Typography variant="subtitle1">
                            Add new product to the system.
                        </Typography>
                    </div>
                    <div>
                        <Button variant="outlined" className="ms-3" color="primary" onClick={postOnClick} >Publish</Button>
                    </div>
                </Box>
                <hr />
                <div className="row">
                    <div className=" col-sm-12 col-lg-8 d-sm-flex justify-content-center flex-column">
                        <Box m={4} mx={1}>
                            <FormGroup className="needs-validation">
                                <FormControl variant="outlined">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Product Title</Typography>
                                    <TextField id="producttitle" required={true} variant="outlined" placeholder="Product title" onChange={e => { productInforef.current.title = e.target.value }} />
                                </FormControl>
                                <div className="mt-4 editer-add"  >
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Product Description</Typography>
                                    <Editor
                                        onInit={(evt, editor) => editorDesc.current = editor}
                                        initialValue=""
                                        apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                            content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            images_upload_handler: async (blobInfo, success, failure, _) => {
                                                const file = blobInfo.blob()
                                                const tinyMcResult = await ApiHelper.uploadImage({ image: file }, "product")
                                                if (tinyMcResult !== "") {
                                                    success(tinyMcResult.data[0].uri)
                                                } else {
                                                    failure('Something Went Wrong')
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="mt-4 editer-add">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Product Benefits</Typography>
                                    <Editor
                                        onInit={(evt, editor) => editorBenifit.current = editor}
                                        initialValue=""
                                        apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                            content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            images_upload_handler: async (blobInfo, success, failure, _) => {
                                                const file = blobInfo.blob()
                                                const tinyMcResult = await ApiHelper.uploadImage({ image: file }, "product")
                                                if (tinyMcResult !== "") {
                                                    success(tinyMcResult.data[0].uri)
                                                } else {
                                                    failure('Something Went Wrong')
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="mt-4 p-0 editer-add">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Product Ingrediants</Typography>
                                    <Editor
                                        onInit={(evt, editor) => editorIngrediants.current = editor}
                                        initialValue=""
                                        apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                            content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            images_upload_handler: async (blobInfo, success, failure, _) => {
                                                const file = blobInfo.blob()
                                                const tinyMcResult = await ApiHelper.uploadImage({ image: file }, "product")
                                                if (tinyMcResult !== "") {
                                                    success(tinyMcResult.data[0].uri)
                                                } else {
                                                    failure('Something Went Wrong')
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        </Box>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <Box m={4} mt={7} mx={1}>
                            <div className="mb-4 text-left">
                                <div style={{ backgroundImage: `url(${myFeatureImage.uri ?? myFeatureImage.uri})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100%", height: 200, borderWidth: "1px", borderStyle: "double" }}>
                                </div>
                                {/* {
                                        featureImageUri ?? featureImageUri.length > 0 ? (
                                            featureImageUri.map((src, index) => (
                                                <img src={src.uri} className="img-fluid" width={180} />
                                            ))
                                        ) : (<></>)
                                    } */}
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <Button
                                            style={{ width: '100%' }}
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                        >
                                            Upload Gallery
                                            <input
                                                onChange={e => { fileUpload(e.target.files) }}
                                                type="file"
                                                hidden
                                                multiple
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            {
                                featureImageUri && featureImageUri.length ? (
                                    <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                        <Button aria-controls="simple-menu" variant="outlined" aria-haspopup="true" onClick={handleGalleryOpen}>
                                            Select Feature Image
                                        </Button>
                                        {/* <Button aria-controls="simple-menu" variant="outlined" aria-haspopup="true" onClick={handleClick}>
                                                Select Feature Image
                                            </Button> */}
                                        {/* <Menu
                                                id="FeatureImageSelection"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                {
                                                    featureImageUri && featureImageUri.length > 0 ? (featureImageUri.map(e => (
                                                        <MenuItem key={e.file_name} data-image={e} onClick={event => HandleSetMyFeatureImage(event, e)}><img alt={e.file_name} src={e.uri} className="img-fluid" width={150} /></MenuItem>
                                                    ))) : (<></>)
                                                }
                                            </Menu> */}
                                    </FormControl>
                                ) : (<></>)
                            }

                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <Select
                                    native
                                    // value={productInfo.category}
                                    onChange={e => { productInforef.current.category = e.target.value }}
                                    label="Select category"
                                    inputProps={{
                                        name: 'category',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="">
                                        Choose Category
                                    </option>
                                    {typeof categorySelect !== "undefined" && categorySelect.length > 0 ? categorySelect.map(category => (
                                        <option key={category.catid ?? category.catid} aria-label={category.title ?? category.title} value={category.catid ?? category.catid}>
                                            {category.title ?? category.title}
                                        </option>
                                    )) : (<></>)}
                                </Select>
                            </FormControl>
                            {/* <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                    <Select
                                        native
                                        value={productInfo.pack_of}
                                        onChange={e => { HadleChangePostInput('pack_of', e.target.value) }}
                                        label="Select Pack of"
                                        inputProps={{
                                            name: 'pack_of',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="">
                                            Choose Pack of
                                        </option>
                                        Below code was commented
                                        {typeof categorySelect !== "undefined" && categorySelect.length > 0 ? categorySelect.map(category => (
                                            <option key={category.catid ?? category.catid} aria-label={category.title ?? category.title} value={category.catid ?? category.catid}>
                                                {category.title ?? category.title}
                                            </option>
                                        )) : (<></>)}
                                    </Select>
                                </FormControl> */}
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="productslug" onFocus={e => { e.target.value = productInforef.current.title.split(" ").join("-").toLowerCase(); productInforef.current.product_permalink = e.target.value }} onChange={(e) => { productInforef.current.product_permalink = e.target.value }} variant="outlined" placeholder="Product Permalink" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="productsku" onChange={(e) => { productInforef.current.sku = e.target.value }} variant="outlined" placeholder="Product SKU" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="productoprice" type="number" onChange={(e) => { productInforef.current.sales_price = Number(e.target.value) }} variant="outlined" placeholder="Product Selling Price (₹)" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="regular_price" type="number" onChange={(e) => { productInforef.current.regular_price = Number(e.target.value) }} variant="outlined" placeholder="Product Regular Price (₹)" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="quantity" type="number" onChange={(e) => { productInforef.current.quantity = Number(e.target.value) }} variant="outlined" placeholder="Quantity" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="discount_for_online" type="number" onChange={(e) => { productInforef.current.discount_for_online = Number(e.target.value) }} variant="outlined" placeholder="Online Discount (%)" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="tags" onChange={(e) => { productInforef.current.tags = e.target.value }} variant="outlined" placeholder="Meta Tags" />
                                <span className="text-muted">Seperated by comma(,)</span>
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="metatitle" onChange={(e) => { productInforef.current.meta_title = e.target.value }} variant="outlined" placeholder="Meta title" />
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField fullWidth multiline rowsMax={5} rows={5} id="metadescription" onChange={(e) => { productInforef.current.meta_description = e.target.value }} variant="outlined" placeholder="Meta description" />
                            </FormControl>
                            <div>
                                <FormControl component="fieldset" className="mt-4">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Stock Status</Typography>
                                    <RadioGroup defaultValue="true" aria-label="stock_status" name="stock_status" onChange={(e) => { productInforef.current.stock = e.target.value }}>
                                        <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={"false"} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl component="fieldset" className="mt-4 mb-4">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Suppliment For</Typography>
                                    <RadioGroup defaultValu="male" aria-label="suppliment_for" name="suppliment_for" onChange={e => { productInforef.current.suppliment_for = e.target.value }}>
                                        <FormControlLabel value={"male"} control={<Radio />} label="Male" />
                                        <FormControlLabel value={"female"} control={<Radio />} label="Female" />
                                        <FormControlLabel value={"both"} control={<Radio />} label="Both" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <Typography variant="subtitle1" className="text-muted fw-bold">Product Short Content</Typography>
                                <Editor
                                    onInit={(evt, editor) => editorShort_content.current = editor}
                                    initialValue=""
                                    apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                    init={{
                                        height: 500,
                                        max_height:190,
                                        menubar: false,
                                        plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                        content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        images_upload_handler: async (blobInfo, success, failure, _) => {
                                            const file = blobInfo.blob()
                                            const tinyMcResult = await ApiHelper.uploadImage({ image: file }, "product")
                                            if (tinyMcResult !== "") {
                                                success(tinyMcResult.data[0].uri)
                                            } else {
                                                failure('Something Went Wrong')
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </Box>
                    </div>
                </div>

            </div>
            <Dialog
                open={isGalleryOpen}
                onClose={handleGalleryClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Select feature image</DialogTitle>
                <DialogContent>
                    {Array.isArray(featureImageUri) && featureImageUri.length > 0 ? <div>
                        {
                            generateFeaturedImageContent().map(item => <div className="row mb-2">
                                {
                                    item.map(eItem => <div key={eItem.uri} className="col"><img alt="Gallery Images" onClick={() => HandleSetMyFeatureImage(eItem)} className="img-thumbnail h-100" style={{ objectFit: 'cover' }} src={eItem.uri} /></div>)
                                }
                            </div>)
                        }
                    </div> : (<></>)}
                </DialogContent>
            </Dialog>
        </div >
    )
}
