import React, { useEffect, useState } from 'react'
import { Box, Typography, Divider, IconButton, Button, TextField, Select, MenuItem, FormControl } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid';
import NavBar from '../../widget/NavBar';
import { ApiHelper as ApiHelperC } from '../../helpers';
import moment from 'moment'
import PATH from '../../helpers/Routes';
import { EditOutlined } from '@material-ui/icons';
import "./order.css"
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
export default function ViewOrder() {
    const ApiHelper = new ApiHelperC()
    const [isLoading, setIsLoading] = useState(true)
    const [dataRows, setDataRows] = useState([])
    const [filteredDataRows, setFilteredDataRows] = useState([])
    const columns = [
        {
            field: 'orderid', headerName: 'OrderID', width: 150, renderCell: (params) => {
                return (
                    <a target="blank" className="row-order-link" href={`/#${PATH.order.edit.replace(":id", params.row.orderid)}`}>{params.row.orderid}</a>
                )
            }
        },
        { field: 'full_name', headerName: 'Customer', width: 200 },
        {
            field: 'raz_order_id', headerName: 'Razorpay ID', width: 270, renderCell: (cell) => {
                if (cell.value === null) {
                    return "N/A"
                } else {
                    return cell.value
                }
            }
        },
        {
            field: 'raz_payment_id', headerName: 'Payment ID', width: 270, renderCell: (cell) => {
                if (cell.value === null) {
                    return "N/A"
                } else {
                    return cell.value
                }
            }
        },
        { field: 'total_price', headerName: 'Total Price', width: 150 },
        { field: 'type', headerName: 'Type', width: 150 },
        {
            field: 'payment_status', headerName: 'Staus', width: 150, renderCell: (cell) => {
                if (cell.value === "pending") {
                    return <Typography variant="caption" color="secondary" className="fw-bold text-uppercase">{cell.value}</Typography>
                } else if (cell.value === "canceled") {
                    return <Typography variant="caption" color="error" className="fw-bold text-uppercase">{cell.value}</Typography>
                } else if (cell.value === "success") {
                    return <Typography variant="caption" color="primary" className="fw-bold text-uppercase">{cell.value}</Typography>
                }
            }
        },
        {
            field: 'created_at', headerName: 'Order At', type: 'dateTime', width: 250, renderCell: (cell) => {
                return moment(cell.value).format("YYYY-MM-DD hh:mm:ss A")
            }
        },
        {
            field: '', headerName: 'Action', width: 250, renderCell: (params) => (
                <Link to={`/order/edit/${params.row.orderid}`}>
                    <IconButton color="primary">
                        <EditOutlined />
                    </IconButton>
                </Link>

            )
        },

    ];
    const [filterState, setFilterState] = useState({
        searchTerm: '',
        startDate: '',
        endDate: '',
        orderType: ''
    })


    const headers = [
        { label: "Order ID #", key: "orderid" },
        { label: "Payment Staus", key: "payment_status" },
        { label: "Razorpay ID #", key: "raz_order_id" },
        { label: "Payment ID #", key: "raz_payment_id" },
        { label: "Order Type", key: "type" },
        { label: "Total Price", key: "total_price" },
        { label: "Customer Name", key: "full_name" },
        { label: "Order AT", key: "created_at" },

    ]
    // console.log(dataRows)

    const csvReport = {
        data: filteredDataRows.length > 0 ? filteredDataRows : dataRows,
        headers: headers,
        filename: 'Orders.csv'
    };
    // const isueHandler = () => {
    //     console.log(params.value)
    // }
    async function fetchOrderDetails() {
        const result = await ApiHelper.fetchOrderDetails()
        if (result.status === "success") {
            setIsLoading(false)
            setDataRows([...result.data])
        } else {
            setIsLoading(false)
        }
    }

    const hasFilter = () => {
        if (filterState.startDate !== '') return true
        if (filterState.endDate !== '') return true
        if (filterState.orderType !== '') return true
        if (filterState.searchTerm !== '') return true
        return false
    }


    const handleStartDateChange = (e) => {
        setFilterState(oldState => ({ ...oldState, startDate: e.target.value }))
        //console.log(e.target.value)
        //updateUI()
    }

    const handleEndDateChange = (e) => {
        setFilterState(oldState => ({ ...oldState, endDate: e.target.value }))
        //console.log(e.target.value)
        //updateUI()
    }

    const handleOrderTypeChange = (e) => {
        setFilterState(oldState => ({ ...oldState, orderType: e.target.value }))
        //console.log(e.target.value)
        //updateUI()
    }

    const handleSearchTermChange = (e) => {
        setFilterState(oldState => ({ ...oldState, searchTerm: e.target.value }))
        //console.log(e.target.value)
        //updateUI()
    }

    useEffect(() => {
        fetchOrderDetails()
        return () => {
            setIsLoading(true);
            setDataRows([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const orderTypeAlias = (order, alias) => {
        if (alias === 'all') return true
        if (alias === 'pending' && order.payment_status === 'pending') return true
        if (alias === 'success_cod' && order.type === 'cod') return true
        if (alias === 'success_online' && order.type === 'online' && order.payment_status === 'success') return true
        if (alias === 'canceled' && order.payment_status === 'canceled') return true
        return false
    }

    useEffect(() => {
        const updateUI = () => {
            //console.log('Updating UI')
            let ndata = dataRows;
            if (filterState.startDate !== '') {
                ndata = ndata.filter(e => moment(e.created_at).isSameOrAfter(filterState.startDate))
            }

            if (filterState.endDate) {
                ndata = ndata.filter(e => moment(e.created_at).isSameOrBefore(filterState.endDate))
            }

            if (filterState.orderType !== '') {
                //console.log(filterState.orderType)
                ndata = ndata.filter(e => orderTypeAlias(e, filterState.orderType))
            }

            if (filterState.searchTerm !== '') {
                // eslint-disable-next-line
                ndata = ndata.filter(e => typeof e.full_name === 'string' && e.full_name.toLowerCase().includes(filterState.searchTerm.toLowerCase()) || Number(e.orderid) === Number(filterState.searchTerm) || e.phone.toLowerCase().includes(filterState.searchTerm.toLowerCase()) || e.email.toLowerCase().includes(filterState.searchTerm.toLowerCase()))
            }

            setFilteredDataRows(ndata)
        }
        updateUI()
    }, [dataRows, filterState])

    // console.log(dataRows)
    document.title = `View All Oreders`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2} style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            All Order Detail
                        </Typography>
                        <Typography variant="subtitle1">
                            All Order Detail Of Your Customer
                        </Typography>
                    </div>
                    <div className="p-0">
                        <Button variant="outlined" className="m-0 mt-3" color="primary"><CSVLink style={{ color: "#3f51b5", textDecoration: "none" }} {...csvReport}>Export to CSV</CSVLink></Button>
                    </div>
                </Box>
                <Divider />
                <Box m={4}>
                    <form noValidate>
                        <div className="row">
                            <div className="col-12 col-lg-3 col-md-3 mb-4">
                                <Typography variant="subtitle1" className="text-muted fw-bold">Start Date</Typography>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        id="startDate"
                                        type="datetime-local"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={filterState.startDate}
                                        onChange={handleStartDateChange}
                                    //value={date.start}
                                    //onChange={(e) => updateGrid(e, "start")}
                                    //onChange={(e) => handleStartChange(e)}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 mb-4">
                                <FormControl fullWidth variant="outlined">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">End Date</Typography>
                                    <TextField
                                        id="endDate"
                                        type="datetime-local"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={filterState.endDate}
                                        onChange={handleEndDateChange}
                                    // value={date.end}
                                    //onChange={(e) => handleEndChange(e)}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 mb-4">
                                <FormControl fullWidth>
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Order Type</Typography>
                                    <Select labelId="label" name="crm_status" value={filterState.orderType} id="state" displayEmpty inputProps={{
                                        name: 'crm_status',
                                        id: 'outlined-age-native-simple',
                                    }}
                                        onChange={handleOrderTypeChange}
                                    //onChange={(e) => setsearch(oldState => { return { ...oldState, type: e.target.value } })}
                                    >

                                        <MenuItem disabled={true} value="">
                                            <em>--Select-Order-Type--</em>
                                        </MenuItem>
                                        <MenuItem value="all">
                                            All
                                        </MenuItem>
                                        <MenuItem value="pending">
                                            Proccess
                                        </MenuItem>
                                        <MenuItem value="success_cod">
                                            Success(COD)
                                        </MenuItem>
                                        <MenuItem value="success_online">
                                            Success(Online)
                                        </MenuItem>
                                        <MenuItem value="canceled">
                                            Cancel
                                        </MenuItem>
                                        {/* {
                                                    crmStatusList && crmStatusList.map((e) => (
                                                        <MenuItem key={e} value={e}>
                                                            {e.toUpperCase()}
                                                        </MenuItem>
                                                    ))
                                                } */}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 mb-4">
                                <FormControl fullWidth variant="outlined">
                                    <Typography variant="subtitle1" className="text-muted fw-bold">Search</Typography>
                                    <TextField type="text" name="search" value={filterState.searchTerm} id="search" placeholder="Search order"
                                        //onChange={(e) => setsearch({ ...search, value: e.target.value })}
                                        onChange={handleSearchTermChange}
                                    ></TextField>
                                </FormControl>
                            </div>
                        </div>

                    </form>
                </Box>
                <Box m={4}>
                    <DataGrid loading={isLoading} autoHeight={true}
                        rows={hasFilter() ? filteredDataRows : dataRows}
                        getRowId={e => { return e.orderid }} columns={columns} pageSize={10} />
                </Box>
            </div>
        </div>
    )
}
