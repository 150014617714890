import { Box, Button, FormControl, FormGroup, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import publicIp from 'public-ip';
import React, { useEffect, useRef, useState } from 'react';
import ErrorBox from '../../functions/ErrorBox';
import { totalPrice } from '../../functions/TotalPrice';
import { ApiHelper as ApiHelperC } from '../../helpers';
import PATH from '../../helpers/Routes';
import NavBar from '../../widget/NavBar';

export default function AddOrder(porps) {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const [products, setproducts] = useState()                  // FOR PRODUCT DETAILS
    const [dataRows, setDataRows] = useState([])
    const [tempProid, setTempProid] = useState([])
    const [selectedProduct, setSelectedProduct] = useState("")
    // const [orderModel, setorderModel] = useState({
    //     customer_info: {
    //         customer_name: "", customer_phone: "", customer_email: "", gender: "",
    //     },
    //     address_info: {
    //         address_line_1: "",
    //         address_line_2: "",
    //         landmark: "",
    //         country: "india",
    //         state: "",
    //         district: "",
    //         pincode: "",
    //         city: "",
    //     },
    //     payment_info: {
    //         payment_method: "",
    //     },
    //     total_paid_amount: 0
    // })
    const [pinstatecity, setpinstatecity] = useState({
        state:"",
        city: "",
        pincode: "",
    })
    const orderModel = useRef({
        customer_info: {
            customer_name: "",
            customer_phone: "",
            customer_email: "",
            gender: "",
        },
        address_info: {
            address_line_1: "",
            address_line_2: "",
            landmark: "",
            country: "india",
            district: "",
        },
        payment_info: {
            payment_method: "",
        }
    })
    const ApiHelper = new ApiHelperC()
    async function dataHandler() {
        const products = await ApiHelper.fetchProducts()
        setproducts(products.data)
    }

    async function StateData() {
        if (pinstatecity.pincode.length > 3) {
            let response = await ApiHelper.getstate(pinstatecity.pincode)
            if (response && response.data) {
                if (response.data.length === 1) {
                    setpinstatecity({ ...pinstatecity,state: response.data[0].state, city: response.data[0].district})
                }
            }
        }
    }
    let stateValue = document.getElementById("state")
    if(stateValue !== null){
        stateValue.value = orderModel.current.address_info.state
    }
    // console.log(orderModel.address_info.state)
    async function CreatedingOrders(data) {
        // console.log(data.customer_info.customer_email.length)

        let respons = await ApiHelper.createOrders(data)
        for (let fild in data.address_info) {
            if (data.address_info[fild] === "" || data.address_info[fild] === "undefined") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field ${fild} is empty`, OC: true })
            }
        }
        for (let fild in data.customer_info) {
            if (data.customer_info[fild] === "" || data.customer_info[fild] === "undefined") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field ${fild} is empty`, OC: true })
            }
        }
        for (let fild in data.payment_info) {
            if (data.payment_info[fild] === "" || data.payment_info[fild] === "undefined") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field ${fild} is empty`, OC: true })
            }
        }
        if (data.products.length <= 0) {
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Minimum 1 product required for creating new order`, OC: true })
        }
        if (respons.status === "success") {
            const orderid = respons.data[0].orderid
            const pushto = PATH.order.edit.replace(":id", orderid)
            setresponse_data({ ...response_data, status: respons.status, message: respons.message, redirect_to: pushto, OC: true })
        }
    }
    useEffect(() => {
        dataHandler()

        return () => {
            setproducts([])
        }
        // eslint-disable-next-line
    }, [])
    const selectProductHandler = (e, data) => {
        let data_prepare = {}
        data_prepare['proid'] = data.proid
        data_prepare['product_name'] = data.name
        data_prepare['qty'] = 1
        data_prepare['price'] = data.price
        data_prepare['purchase_price'] = data.price

        if (tempProid.indexOf(data.proid) < 0) {
            setTempProid([...tempProid, data.proid])
            setDataRows([...dataRows, data_prepare])
        }
    }
    const removeProduct = (proid) => {
        let isExistIndex = tempProid.indexOf(proid)
        if (isExistIndex > -1) {
            let tempPP = tempProid
            tempPP.splice(isExistIndex, 1)
            setTempProid([...tempPP])
            let tempdataRows = dataRows
            let ddd = []
            tempPP = tempPP.map(pid => {
                return tempdataRows.map(obj => {
                    if (obj.proid === pid) {
                        ddd.push(obj)
                        return obj
                    }
                    return null
                })
            })
            setDataRows([...ddd])
        }
    }
    const productQTYChange = (e, proid, price) => {
        let tempDR = [...dataRows]
        let indexx = ""
        let chagedProduct = tempDR.map((obj, index) => {
            if (obj.proid === proid) {
                let mmm = { ...obj }
                if (e.target.value > 0 && e.target.value < 3) {
                    mmm.qty = e.target.value
                    mmm.price = mmm.qty * price
                    indexx = index
                } else {
                    mmm.qty = 1
                }
                return mmm
            }
            return false
        }).filter(e => typeof e !== "undefined" && e !== false).shift()
        tempDR[indexx] = chagedProduct
        setDataRows([...tempDR])
    }

    const columns = [
        { field: "product_name", headerName: "Product name", width: 200 },
        {
            field: "qty", headerName: "QTY", width: 100, renderCell: (params) => {
                return <Box className="d-flex align-items-center">
                    <TextField type="number" onChange={e => productQTYChange(e, params.row.proid, params.row.purchase_price)} fullWidth={true} className="py-5" id="productslug" value={params.value} variant="filled" placeholder="QTY" />
                </Box>
            }
        },
        {
            field: "price", headerName: "Price", width: 150
        },
        {
            field: "proid", headerName: "Action", width: 150,
            renderCell: (params) => (
                <Button variant="contained" color="secondary"
                    onClick={() => removeProduct(params.value)}>Delete</Button>
            )
        },
    ]
    const CreateOrder = async () => {
        let prepareProducts = dataRows.map(e => {
            return {
                proid: e.proid,
                qty: e.qty,
                price: e.price
            }
        })
        const total_PRICE = totalPrice(dataRows)
        orderModel.current.address_info = {...orderModel.current.address_info,state: pinstatecity.state,city:pinstatecity.city,pincode:pinstatecity.pincode}
        let data = {
            ...orderModel.current,
            total_paid_amount: total_PRICE,
            products: prepareProducts,
            ip_address: await publicIp.v4()
        }
        // return console.log(data)
        CreatedingOrders(data)

    }
    document.title = `Add New Order`
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Add Orders
                        </Typography>
                        <Typography variant="subtitle1">
                            Create new order to the system.
                        </Typography>
                    </div>
                    <div>
                        <Button variant="outlined" className="ms-3" color="primary" onClick={CreateOrder}>Publish</Button>
                    </div>
                </Box>
                <hr />
                <div className="row">
                    <div className="col-12 p-0">
                        <Box m={4}>
                            <h2 className="mb-4">Customer Details</h2>
                            <FormGroup>
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Full Name</Typography>
                                            <TextField type="text" name="fullname" id="fullname" variant="outlined" placeholder="Enter fullname"
                                                onChange={(e) => orderModel.current.customer_info.customer_name = e.target.value} />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Email</Typography>
                                            <TextField type="text" id="email" name="email" variant="outlined" placeholder="abc@example.com"
                                                onChange={(e) => orderModel.current.customer_info.customer_email = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Phone</Typography>
                                            <TextField type="text" id="phone" name="phone" variant="outlined" placeholder="Enter phone number"
                                                onChange={(e) => orderModel.current.customer_info.customer_phone = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <FormControl variant="outlined" className="w-sm">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Select Gender</Typography>
                                            <Select labelId="label" name="gender" id="state" displayEmpty inputProps={{
                                                name: 'gender',
                                                id: 'outlined-age-native-simple',
                                            }}
                                            defaultValue=""
                                                onChange={(e) => { orderModel.current.customer_info.gender = e.target.value }}>
                                                <MenuItem disabled={true} value="">
                                                    <em>--Select-Your-Gender--</em>
                                                </MenuItem>
                                                <MenuItem value="male">
                                                    Male
                                                </MenuItem>
                                                <MenuItem value="female">
                                                    Female
                                                </MenuItem>
                                                <MenuItem value="other">
                                                    Other
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div> <br />
                                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">House/Building</Typography>
                                            <TextField type="text" name="address" id="address" variant="outlined" placeholder="Enter House No./Bulding/Floor"
                                                onChange={(e) => orderModel.current.address_info.address_line_1 = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Colony/Strit/Locality</Typography>
                                            <TextField type="text" name="colony/street/locality" id="colony/street/locality" variant="outlined" placeholder="Enter Colony/Street/Locality"
                                                onChange={(e) => orderModel.current.address_info.address_line_2 = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Landmark</Typography>
                                            <TextField type="text" name="landmark" id="landmark" variant="outlined" placeholder="Enter Landmark : Near/Behind"
                                                onChange={(e) => orderModel.current.address_info.landmark = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4  mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Postal code</Typography>
                                            <TextField type="text" name="pincode" id="pincode" variant="outlined" placeholder="Pincode"
                                                onKeyUp={(e) => StateData()} onChange={(e) => setpinstatecity(old => {return{...old,pincode:e.target.value}})}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">State</Typography>
                                            <TextField type="text" name="state" id="state" variant="outlined" placeholder="Enter state"
                                            value={pinstatecity.state}
                                                onChange={(e) => setpinstatecity(old => {return{...old,state:e.target.value}})}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">District</Typography>
                                            <TextField type="text" name="district" id="district" variant="outlined" placeholder="Enter District"
                                                onChange={(e) => orderModel.current.address_info.district = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    {/* <div className="row"> */}
                                    <div className="col-12 col-md-4 col-lg-4 mb-5">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">City</Typography>
                                            <TextField type="text" name="city" id="city" variant="outlined" placeholder="Enter city" value={pinstatecity.city}
                                                onChange={(e) => setpinstatecity(old => {return{...old,city:e.target.value}})}
                                            />
                                        </FormControl>
                                    </div>
                                    {/* </div> */}
                                    <div className="row m-0 p-0">
                                        <div className="col-6">
                                            <FormControl fullWidth variant="outlined">
                                                <Typography variant="subtitle1" className="text-muted fw-bold">Payment Method</Typography>
                                                <Select name="paymentmethod" labelId="label" id="paymentmethod" defaultValue=""
                                                    onChange={(e) => orderModel.current.payment_info.payment_method = e.target.value}
                                                    displayEmpty inputProps={{
                                                        name: 'paymentmethod',
                                                        id: 'outlined-age-native-simple',
                                                    }}
                                                >
                                                    <MenuItem disabled={true} value="">
                                                        <em>--Select-Payment Methode--</em>
                                                    </MenuItem>
                                                    <MenuItem value="online">Online</MenuItem>
                                                    <MenuItem value="cod">Cash on delivery</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-6">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Select Product</Typography>
                                            <FormControl fullWidth variant="outlined">
                                                <Select value={selectedProduct} name="product"
                                                    onChange={e => {
                                                        setSelectedProduct(e.target.value)
                                                    }}
                                                    displayEmpty inputProps={{
                                                        name: 'product',
                                                        id: 'outlined-age-native-simple',
                                                    }} id="product"
                                                >
                                                    <MenuItem disabled={true} value="">
                                                        <em>--Select-Product--</em>
                                                    </MenuItem>
                                                    {
                                                        products && (products.map((product) => (
                                                            <MenuItem onClick={e => selectProductHandler(e, product)} key={product.id} value={product.proid}>{product.name}</MenuItem>
                                                        )))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </Box>
                        <hr style={{ width: "97%", margin: "0 auto" }} />
                        <Box m={4}>
                            <div className="text-muted" style={{ textAlign: "end", fontWeight: "bold", fontSize: "1.1rem", paddingRight: "1rem", }}>
                                Total :	&#8377; {totalPrice(dataRows)}
                            </div>
                            <div style={{ height: "auto", width: '100%' }}>
                                <DataGrid
                                    rows={dataRows}
                                    autoHeight
                                    getRowId={e => e.proid}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </div >
    )
}
