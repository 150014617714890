import { Box, Button, Divider, FormControl, FormGroup, IconButton, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { EditOutlined } from '@material-ui/icons';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
// import ConfirmBox from '../../functions/ConfirmBox';
import ErrorBox from '../../functions/ErrorBox';
import { totalPrice } from '../../functions/TotalPrice';
import { ApiHelper as ApiHelperC } from '../../helpers';
import PATH from '../../helpers/Routes';
import NavBar from '../../widget/NavBar';


export default function Editorder(props) {
    // const [confirm, setconfirm] = useState({
    //     open: false,
    //     agree: false
    // })
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const orderID = props.match.params.id
    const history = useHistory()
    const [disposition, setdisposition] = useState([])
    const [crmStatusList, setCRMSTATUSLIST] = useState([
        "verify", "reject", "working"
    ])
    const [dataRows, setDataRows] = useState([]) // FOR PRODUCT DETAILS AND ORDER ID
    const [OrderHistorydataRows, setOrderHistorydataRows] = useState([])
    const [CRM_status, setCRM_status] = useState({
        status: "",
        disposition: "",
        crm_id: "",
        courirer_service: "blue_dart",
        tracking_id: "",
        disposition_discription: "",
    })


    const [orderModel, setorderModel] = useState({
        customer_info: {
            gender: "",
        },
        address_info: {
            country: "",
            state: "",
            pincode: "",
            city: "",
        },
        payment_info: {
            payment_status: "",
            payment_method: "",
        },
        total_paid_amount: 0
    })
    const ApiHelper = new ApiHelperC()
    const crmHandler = async () => {
        let data;
        if (CRM_status.status === "verify") {
            data = {
                status: CRM_status.status,
                crmid: CRM_status.crm_id,
                disposition_desc: CRM_status.disposition_discription
            }
        } else if (CRM_status.status === "reject" || CRM_status.status === "working") {
            data = {
                status: CRM_status.status,
                disposition: CRM_status.disposition,
                disposition_desc: CRM_status.disposition_discription
            }
        } else if (CRM_status.status === "dispatch") {
            data = {
                status: CRM_status.status,
                courirer_service: CRM_status.courirer_service,
                tracking_id: CRM_status.tracking_id,
                disposition_desc: CRM_status.disposition_discription
            }
        }
        if (data.status === "verify") {
            if (data.crmid === "" || data.crmid === "undefined" || data.crmid === "null" || data.crmid === null) {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field CRM Id is empty`, OC: true })
            }
        }
        if (data.status === "dispatch") {
            if (data.tracking_id === "" || data.tracking_id === "undefined" || data.tracking_id === "null" || data.tracking_id === null) {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field Traking id is empty or not valid`, OC: true })
            }
            if (data.courirer_service === "" || data.courirer_service === "undefined" || data.courirer_service === "null" || data.courirer_service === " ") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field Courirer service is empty`, OC: true })
            }
        }
        if (data.status === "reject" || data.status === "working") {
            if (data.disposition === "" || data.disposition === " " || data.disposition === undefined || data.disposition === "undefined") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field Traking id is empty or not valid`, OC: true })
            }
        }
        let response = await ApiHelper.crmStatus(data, orderID)
        if (response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: `CRM status update for order Id # ${orderID} (Order transfred by ${CRM_status.status} order table)`, OC: true })
            getordersDetails()
        }
    }

    const OrderRef = useRef({
        address_info: {
            address_line_1: "",
            address_line_2: "",
            landmark: "",
            district: ""
        },
        customer_info: {
            customer_name: "",
            customer_email: "",
            customer_phone: "",
        }
    })

    const getordersDetails = async () => {
        const response = await ApiHelper.getOrder(orderID)
        setorderModel({
            customer_info: {
                gender: response.gender
            },
            address_info: {
                country: "india",
                state: response.state,
                pincode: response.pincode,
                city: response.city,
            },
            payment_info: {
                payment_method: response.type,
                payment_status: response.payment_status
            },
            total_paid_amount: response.total_price
        })
        OrderRef.current.customer_info.customer_email = response.email
        OrderRef.current.customer_info.customer_name = response.full_name
        OrderRef.current.customer_info.customer_phone = response.phone
        OrderRef.current.address_info.address_line_1 = response.address_line_1
        OrderRef.current.address_info.address_line_2 = response.address_line_2
        OrderRef.current.address_info.landmark = response.landmark
        OrderRef.current.address_info.district = response.district
        const purchaseProduct = response.ordermeta ?? []
        const orderHistory = response.orderhistory ?? []
        setDataRows([...purchaseProduct])
        setOrderHistorydataRows([...orderHistory])
        setdisposition([...response.disposition_list ?? []])
        setCRM_status({ ...CRM_status, ...response.order_room })
        if (typeof response.order_room?.status !== "undefined") {
            if (response.order_room?.status === "verify" || response.order_room?.status === "dispatch") {
                if (crmStatusList.findIndex(e => e === "dispatch") < 0)
                    setCRMSTATUSLIST([...crmStatusList, "dispatch"])
            } else {
                let ind = crmStatusList.findIndex(e => e === "dispatch")
                if (ind > -1) {
                    let tempLIST = [...crmStatusList]
                    tempLIST.splice(ind, 1)
                    setCRMSTATUSLIST([...tempLIST])
                }
            }
        }
    }

    const deleteHandler = async () => {
        // setconfirm({agree:false,open:true})
        // }
        // const confirmdeletOrder = async(oid) => {
        const deleteResponse = await ApiHelper.deleteorder(orderID)
        if (deleteResponse.status === "success") {
            let path = PATH.order.view
            setresponse_data({ ...response_data, status: deleteResponse.status, message: `Order ID # ${orderID} successfully deleted`, redirect_to: path, OC: true })
        }
    }
    useEffect(() => {
        // if(confirm.agree === true){
        //     if(orderID.length >= 0 || orderID !== "")
        //     confirmdeletOrder(orderID)
        // }
        getordersDetails()
        return () => {
            getordersDetails({})
        }
        // eslint-disable-next-line
    }, [])



    // async function CreatedingOrders(data) {
    //     let respons = await ApiHelper.createOrders(data)
    //     if (respons.status === "success") {

    //         const orderid = respons.data[0].orderid
    //         const pushto = PATH.order.edit.replace(":id", orderid)
    //         history.push(pushto)
    //     }
    // }


    // const productQTYChange = (e, proid, price) => {
    //     let tempDR = [...dataRows]
    //     let indexx = ""
    //     let chagedProduct = tempDR.map((obj, index) => {
    //         if (obj.proid === proid) {
    //             let mmm = { ...obj }
    //             if (e.target.value > 0 && e.target.value < 3) {
    //                 mmm.qty = e.target.value
    //                 mmm.price = mmm.qty * price
    //                 indexx = index
    //             } else {
    //                 mmm.qty = 1
    //             }
    //             return mmm
    //         }
    //     }).filter(e => typeof e !== "undefined")
    //     chagedProduct = chagedProduct[0]
    //     tempDR[indexx] = chagedProduct
    //     setDataRows([...tempDR])
    // }
    const updateHandler = async () => {
        let data = {
            customer_info: {
                ...orderModel.customer_info,
                customer_name: OrderRef.current.customer_info.customer_name,
                customer_email: OrderRef.current.customer_info.customer_email,
                customer_phone: OrderRef.current.customer_info.customer_phone,
            },
            address_info: {
                ...orderModel.address_info,
                address_line_1: OrderRef.current.address_info.address_line_1,
                address_line_2: OrderRef.current.address_info.address_line_2,
                landmark: OrderRef.current.address_info.landmark,
                district: OrderRef.current.address_info.district,
            },
            payment_info: orderModel.payment_info,
        }
        for (let fild in data.address_info) {
            if (fild === "pincode") {
                if (data.address_info[fild] === "" || data.address_info[fild] === "undefined" || data.address_info[fild] <= 0) {
                    return setresponse_data({ ...response_data, status: "Validaton Error", message: `Required field ${fild} is empty or not valid`, OC: true })
                }
            } else if (fild !== "landmark") {
                if (data.address_info[fild] === "undefined" || data.address_info[fild] === "") {
                    return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field ${fild} is empty`, OC: true })
                }
            }
        }
        for (let fild in data.customer_info) {
            if (data.customer_info[fild] === "" || data.customer_info[fild] === "undefined") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required filed ${fild} is empty`, OC: true })
            }
        }
        for (let fild in data.payment_info) {
            if (data.payment_info[fild] === "" || data.payment_info[fild] === "undefined") {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required field ${fild} is empty`, OC: true })
            }
        }
        const response = await ApiHelper.updateOrders(data, orderID)
        if (response.status === "success") {
            // console.log(response)
            setresponse_data({ ...response_data, status: response.status, message: `Order ID # ${orderID} successfully updated`, OC: true })
        }
    }
    const columns = [
        { field: "product_name", headerName: "Product name", flex: 1 },
        {
            field: "qty", headerName: "QTY", width: 150,
        },
        {
            field: "price", headerName: "Price", flex: 1
        },
    ]
    const orderHistorycolumns = [
        { field: "orderid", headerName: "OrderID", flex: 1 },
        { field: "product_name", headerName: "Product Name", width: 150, flex: 1 },
        { field: "qty", headerName: "QTY", flex: 1 },
        { field: "price", headerName: "Amount Paid", flex: 1, },
        { field: "order_at", type: "date", headerName: "Order At", flex: 1, },
        {
            field: "", headerName: "Action", flex: 1,
            renderCell: (params) => (
                <IconButton color="primary" onClick={() => { history.push(PATH.order.edit.replace(":id", params.row.orderid)); window.location.reload() }}>
                    <EditOutlined />
                </IconButton>
            )
        },
    ]
    async function StateData() {
        if (orderModel.address_info.pincode.length > 3) {
            let response = await ApiHelper.getstate(orderModel.address_info.pincode)
            if (response && response.data) {
                if (response.data.length === 1) {
                    setorderModel(oldState => { return { ...oldState, address_info: { ...oldState.address_info, state: response.data[0].state, city: response.data[0].district } } })
                }
            }
        }
    }
    document.title = `Edit Order # ${orderID}`

    for (let keys in OrderRef.current.customer_info) {
        let getids = document.getElementById(keys)
        if (getids) {
            if (getids !== null) {
                if (getids.id === keys) {
                    getids.value = OrderRef.current.customer_info[keys]
                }
            }
        }
    }
    for (let keys in OrderRef.current.address_info) {
        let getids = document.getElementById(keys)
        if (getids) {
            if (getids !== null) {
                if (getids.id === keys) {
                    getids.value = OrderRef.current.address_info[keys]
                }
            }
        }
    }
    return (
        <div>
            <NavBar />
            {/* <ConfirmBox confirm={confirm} setconfirm={setconfirm}></ConfirmBox> */}
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Edit Orders
                        </Typography>
                        <Typography variant="subtitle1">
                            Edit order #{orderID}.
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end", rowGap: "1rem" }}>
                        <Button variant="outlined" className="ms-3" color="primary" onClick={updateHandler}>Update</Button>
                        <Button className="ms-3" variant="contained" color="secondary" onClick={deleteHandler}>Delete</Button>
                    </div>
                </Box>
                <hr />
                <div className="row">
                    <div className="col-12 p-0">
                        <Box m={4} mb={0} mt={2}>
                            <h2 className="mb-4">
                                CRM Status
                            </h2>
                            <FormGroup>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">CRM status</Typography>
                                            <Select labelId="label" name="crm_status" id="state" value={CRM_status ? CRM_status.status : ""} inputProps={{
                                                name: 'crm_status',
                                                id: 'outlined-age-native-simple',
                                            }}
                                                defaultValue={CRM_status ? CRM_status.status : "working"}
                                                onChange={(e) => setCRM_status(oldState => { return { ...oldState, status: e.target.value } })}>
                                                {
                                                    crmStatusList && crmStatusList.map((e) => (
                                                        <MenuItem key={e} value={e}>
                                                            {e.toUpperCase()}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {
                                        CRM_status.status === "verify" ? (
                                            <div className="col-sm-12 col-lg-4 col-md-6 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold">CRM id</Typography>
                                                    <TextField type="text" value={CRM_status && CRM_status.crm_id} name="fullname" id="fullname" variant="outlined" placeholder="Enter CRM id"
                                                        onChange={(e) => setCRM_status({ ...CRM_status, crm_id: e.target.value })}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                        ) : CRM_status.status !== "verify" && CRM_status.status !== "" && CRM_status.status !== "dispatch" &&
                                        (
                                            <div className="col-sm-12 col-lg-4 col-md-6 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold">Disposition</Typography>
                                                    <Select labelId="label" name="crm_status" id="state" value={CRM_status && CRM_status.disposition !== null && CRM_status.disposition !== "null" && CRM_status.disposition !== "" ? CRM_status.disposition : " "} displayEmpty inputProps={{
                                                        name: 'crm_status',
                                                        id: 'outlined-age-native-simple',
                                                    }}
                                                        onChange={(e) => setCRM_status(oldState => { return { ...oldState, disposition: e.target.value } })}>
                                                        <MenuItem disabled value={" "}>--Select-Disposition--</MenuItem>
                                                        {
                                                            disposition && (disposition.map((x) => (
                                                                <MenuItem key={x.id} value={x.id}>{x.disposition}</MenuItem>
                                                            )))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                    {
                                        CRM_status.status === "dispatch" && (
                                            <div className="col-sm-12 col-lg-4 col-md-6 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold">Courirer Service</Typography>
                                                    <Select labelId="label" value={CRM_status.courirer_service === "" || CRM_status.courirer_service === null || CRM_status.courirer_service === "null" ? " " : CRM_status.courirer_service} onChange={(e) => setCRM_status(oldState => { return { ...oldState, courirer_service: e.target.value } })} name="courirer_status" id="courirer_status"
                                                    >
                                                        <MenuItem disabled value=" ">
                                                            --Select-Courirer-Service--
                                                        </MenuItem>
                                                        <MenuItem value="blue_dart">
                                                            Blue Dart
                                                        </MenuItem>
                                                        <MenuItem value="ecom_express">
                                                            Ecom Express
                                                        </MenuItem>
                                                        <MenuItem value="indian_post">
                                                            Indian Post
                                                        </MenuItem>
                                                        <MenuItem value="delhivery">
                                                            Delhivery
                                                        </MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                    {
                                        CRM_status.status === "dispatch" && (
                                            <div className="col-sm-12 col-lg-4 col-md-6 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold">Tracking Id</Typography>
                                                    <TextField type="text" value={CRM_status.tracking_id} onChange={(e) => setCRM_status(oldState => { return { ...oldState, tracking_id: e.target.value } })} name="fullname" id="fullname" variant="outlined" placeholder="Enter Tracking id"

                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Dispostion discription</Typography>
                                            <TextField type="text" name="disposition_description" id="disposition_description" value={CRM_status.disposition_discription} variant="outlined" placeholder="Type discription"
                                                onChange={(e) => setCRM_status(oldState => { return { ...oldState, disposition_discription: e.target.value } })}
                                            ></TextField>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Button variant="outlined" color="primary" onClick={crmHandler}>Save</Button>
                                </div>
                            </FormGroup>
                        </Box>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-0">
                        <Box m={4}>
                            <h2 className="mb-4">Customer Details</h2>
                            <FormGroup>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Full Name</Typography>
                                            <TextField type="text" name="fullname" id="customer_name" variant="outlined" placeholder="Enter fullname"
                                                onChange={(e) => OrderRef.current.customer_info.customer_name = e.target.value}></TextField>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Email</Typography>
                                            <TextField type="text" id="customer_email" name="email" variant="outlined" placeholder="abc@example.com"
                                                onChange={(e) => OrderRef.current.customer_info.customer_email = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Phone</Typography>
                                            <TextField type="text" id="customer_phone" name="phone" variant="outlined" placeholder="Enter phone number"
                                                onChange={(e) => OrderRef.current.customer_info.customer_phone = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="row p-0 m-0">
                                        <div className="col-sm-12 col-lg-4 col-md-6 mb-4">
                                            <FormControl fullWidth variant="outlined" className="w-sm">
                                                <Typography variant="subtitle1" className="text-muted fw-bold">Select Gender</Typography>
                                                <Select labelId="label" name="gender" displayEmpty
                                                    value={orderModel.customer_info.gender}
                                                    inputProps={{
                                                        name: 'gender',
                                                    }}
                                                    onChange={(e) => setorderModel(oldState => { return { ...oldState, customer_info: { ...oldState.customer_info, gender: e.target.value } } })}>
                                                    <MenuItem disabled={true} value="">
                                                        <em>--Select-Your-Gender--</em>
                                                    </MenuItem>
                                                    <MenuItem value="male">
                                                        Male
                                                    </MenuItem>
                                                    <MenuItem value="female">
                                                        Female
                                                    </MenuItem>
                                                    <MenuItem value="other">
                                                        Other
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div> <br />
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">House/Building No.</Typography>
                                            <TextField type="text" name="address" id="address_line_1" variant="outlined" placeholder="Enter House No./Bulding/Floor"
                                                onChange={(e) => OrderRef.current.address_info.address_line_1 = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Colony/Strit/Locality</Typography>
                                            <TextField type="text" name="colony/street/locality" id="address_line_2" variant="outlined" placeholder="Enter Colony/Street/Locality"
                                                onChange={(e) => OrderRef.current.address_info.address_line_2 = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Landmark</Typography>
                                            <TextField type="text" name="landmark" id="landmark" variant="outlined" placeholder="Enter Landmark : Near/Behind"
                                                onChange={(e) => OrderRef.current.address_info.landmark = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Postal code</Typography>
                                            <TextField type="text" name="pincode" variant="outlined" placeholder="Pincode" value={orderModel.address_info.pincode}
                                                onKeyUp={(e) => StateData()} onChange={(e) => setorderModel(oldState => { return { ...oldState, address_info: { ...oldState.address_info, pincode: e.target.value } } })}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">State</Typography>
                                            <TextField type="text" name="state" value={orderModel.address_info.state} variant="outlined" placeholder="Enter state"
                                                onChange={(e) => setorderModel(oldState => { return { ...oldState, address_info: { ...oldState.address_info, state: e.target.value } } })}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 mb-4">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">District</Typography>
                                            <TextField type="text" name="district" id="district" variant="outlined" placeholder="Enter District"
                                                onChange={(e) => OrderRef.current.address_info.landmark = e.target.value}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="d-flex">
                                        <div className="col-12 col-sm-12 col-md-4 mb-4">
                                            <FormControl fullWidth variant="outlined">
                                                <Typography variant="subtitle1" className="text-muted fw-bold">City</Typography>
                                                <TextField type="text" name="city" variant="outlined" placeholder="Enter City" value={orderModel.address_info.city}
                                                    onChange={(e) => setorderModel(oldState => { return { ...oldState, address_info: { ...oldState.address_info, city: e.target.value } } })}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-5">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Payment Status</Typography>
                                            <Select labelId="label" name="paymentstatus" displayEmpty inputProps={{
                                                name: 'paymentstatus',
                                                id: 'outlined-age-native-simple',
                                            }}
                                                value={orderModel.payment_info.payment_status}
                                                onChange={(e) => setorderModel(oldState => { return { ...oldState, payment_info: { ...oldState.payment_info, payment_status: e.target.value } } })}
                                            >
                                                <MenuItem disabled={true} value="">
                                                    <em>--Choose-Payment Methode--</em>
                                                </MenuItem>
                                                <MenuItem value="0">Proccess</MenuItem>
                                                <MenuItem value="-1">Cancel</MenuItem>
                                                <MenuItem value="1">Success</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6 mb-5">
                                        <FormControl fullWidth variant="outlined">
                                            <Typography variant="subtitle1" className="text-muted fw-bold">Payment Method</Typography>
                                            <Select name="paymentmethod" labelId="label"
                                                value={orderModel.payment_info.payment_method}
                                                onChange={(e) => setorderModel(oldState => { return { ...oldState, payment_info: { ...oldState.payment_info, payment_method: e.target.value } } })}
                                                displayEmpty inputProps={{
                                                    name: 'paymentmethod',
                                                    id: 'outlined-age-native-simple',
                                                }}
                                            >
                                                <MenuItem disabled={true} value="">
                                                    <em>--Select-Payment Methode--</em>
                                                </MenuItem>
                                                <MenuItem value="online">Online</MenuItem>
                                                <MenuItem value="cod">Case on delivery</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </FormGroup>
                        </Box>
                        <Divider></Divider>
                        <Box m={4}>
                            <div className="text-muted" style={{ textAlign: "end", fontWeight: "bold", fontSize: "1.1rem", paddingRight: "1rem", }}>
                                Total :	&#8377; {totalPrice(dataRows)}
                            </div>
                            <Typography variant="h5" className="fw-bold my-2">Selected Products</Typography>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    rows={dataRows}
                                    autoHeight
                                    getRowId={e => e.proid}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                />
                            </div>
                        </Box>
                        <Box m={4}>
                            <Typography variant="h5" className="fw-bold my-2">Order History</Typography>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    rows={OrderHistorydataRows}
                                    autoHeight
                                    getRowId={e => e.proid}
                                    columns={orderHistorycolumns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </div >
    )
}
