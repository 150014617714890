import { Box, Divider, Typography } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import React, { useEffect, useState } from 'react'
import NavBar from '../../widget/NavBar'
import { ApiHelper as ApiHelperC } from '../../helpers'

export default function Inquiry() {
    const [row, setrow] = useState()
    const ApiHelper = new ApiHelperC()
    const inquiryDetails = async() => {
        const response = await  ApiHelper.GetInquiry()
        setrow(response.data)
    }
    useEffect(() => {
        inquiryDetails() // eslint-disable-next-line
    }, [])
    // console.log(row)
    const columns = [
        { field: "id", headerName: "Id #", width:200 },
        { field: "name", headerName: "Name", width:200 },
        { field: "email", headerName: "Email", width:200 },
        { field: "phone", headerName: "Phone", width:200 },
        { field: "message", headerName: "Message", width:200 },
        { field: "created_at", headerName: "Date", width:200},
    ]
    document.title = `Inquiry`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2}>
                    <Typography variant="h5">
                        Inquirys
                    </Typography>
                    <Typography variant="subtitle1">
                        {/* View all Products. */}
                    </Typography>
                </Box>
                <Divider />
                <div className="row mx-0">
                    <div className="col-12 p-0">
                        <Box m={4} mb={0}>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    rows={row ? row : []}
                                    autoHeight
                                    getRowId={e => e.id}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[1]}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}
