import React, { useEffect } from 'react'

import { Redirect, Route } from 'react-router-dom'

import Auth from './AuthHelper'

function ProtectedRouter({ component: Component, ...rest }) {
    useEffect(() => { }, [])
    return (<
        Route {...rest}
        render={
            props => {
                if (new Auth().isAuthenticated()) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: "/",
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        }
    />
    )
}

export default ProtectedRouter