import React, { useEffect, useState } from 'react'
import { Box, Typography, Divider, IconButton } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid';
import NavBar from '../../widget/NavBar';
import { ApiHelper as ApiHelperC } from '../../helpers';
import { EditOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export default function AllPosts() {
    const ApiHelper = new ApiHelperC()
    const [isLoading, setIsLoading] = useState(true)
    const [dataRows, setDataRows] = useState([])


    const visibilityOffOn = async (id) => {
        const response = await ApiHelper.UpdatePostVisibility(id)
        if (response.status === "success") {
            fetchPost()
        }
    }

    const columns = [
        { field: 'title', headerName: 'Post Title', width: 150 },
        // { field: 'category', headerName: 'Category', width: 200 },
        { field: 'permalink', headerName: 'Blog URL', width: 150 },
        { field: 'publish', headerName: 'Published', type: 'boolean', width: 150 },
        { field: 'created_at', headerName: 'Uploaded At', type: 'dateTime', width: 250, },
        {
            field: 'proid', headerName: 'Action', type: 'dateTime', width: 250, renderCell: (cell) => {
                return (
                    <>
                        {cell && cell.row.publish ? (<IconButton color="secondary" onClick={e => { visibilityOffOn(cell.id) }}><VisibilityOutlined /></IconButton>) : (<IconButton onClick={e => { visibilityOffOn(cell.id) }} color="secondary"><VisibilityOffOutlined /></IconButton>)}
                        <Link to={`/post/${cell.id}`}>
                            <IconButton color="secondary">
                                <EditOutlined />
                            </IconButton>
                        </Link>
                    </>
                )

            }

        },
    ];
    // console.log(dataRows)

    async function fetchPost() {
        const result = await ApiHelper.fetchPost()
        // console.log(result)
        if (result.status === "success") {
            setIsLoading(false)
            setDataRows([...result.data])
        }
    }

    useEffect(() => {
        fetchPost()
        return () => {
            setIsLoading(true);
            setDataRows([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    document.title = `View All Post`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2}>
                    <Typography variant="h5">
                        All Post
                    </Typography>
                    <Typography variant="subtitle1">
                        View all posts.
                    </Typography>
                </Box>
                <Divider />
                <Box m={4}>
                    <DataGrid loading={isLoading} autoHeight={true} rows={dataRows} getRowId={e => { return e.post_id }} columns={columns} pageSize={10} />
                </Box>

            </div>
        </div>
    )
}
