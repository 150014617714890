import { Box, Button, FormControl, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useRef, useState } from 'react';
import ErrorBox from '../../functions/ErrorBox';
import { ApiHelper as ApiHelperc } from '../../helpers';
import NavBar from '../../widget/NavBar';

export default function SettingWidget() {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const ApiHelper = new ApiHelperc()


    const [optionState, setOptionState] = useState({
        dataRows: [],
    })
    const optionRef = useRef({
        key: "",value: ""
    })

    const HEADER = [
        { field: '_key', headerName: 'Key', width: 150 },
        { field: '_value', headerName: 'Value', flex: 1 },
        { field: 'created_at', headerName: 'Created At', width: 140, },
    ]
    const fetchInformation = async () => {
        const response = await ApiHelper.fetchOption();
        setOptionState({
            dataRows: [...response.data]
        })
    }

    const settingOnClick = async () => {
        let response = await ApiHelper.createOption({ _key: optionRef.current.key, _value: optionRef.current.value, type: "option" })
        if (response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: response.message, OC: true })
            fetchInformation()
        }
    }

        useEffect(() => {
            fetchInformation()
            return () => {
                setOptionState({ dataRows: [] })
            }
            // eslint-disable-next-line
        }, [])
        document.title = `Information`
        return (
            <div>
                <NavBar />
                <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
                <div className="text-start small-d">
                    <Box mx={4} my={2} >
                        <div style={{ flexGrow: '1' }}>
                            <Typography variant="h5">
                                Add Website Information
                            </Typography>
                            <Typography variant="subtitle1">
                                <b>Note:</b> Contact with the developer if you think to update something over here
                            </Typography>
                        </div>
                    </Box>
                    <hr />
                    <Box>
                        <div className="row m-0">
                            <div className="col-md-6 col-lg-4 col-sm-12">
                                <Box m={4}>
                                    <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                        <TextField id="key"
                                            onChange={(e) => optionRef.current.key = e.target.value} variant="outlined" placeholder="Key Name" />
                                    </FormControl>
                                    <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                        <TextField id="value" onChange={(e) => optionRef.current.value = e.target.value} variant="outlined" placeholder="Value"
                                            fullWidth
                                            multiline={true}
                                            rows={5}
                                        />
                                    </FormControl>
                                    <FormControl className="mt-3 w-100">
                                        <Button variant="outlined" className="w-100" color="primary" onClick={settingOnClick} >Save</Button>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="col-md-6 col-lg-8 col-sm-12">
                                <Box m={4}>
                                    <DataGrid autoHeight={true} rows={optionState.dataRows} getRowId={e => { return e.id }} columns={HEADER} pageSize={10} />
                                </Box>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        )
    }
