import { Box, Button, Drawer, FormControl, FormGroup, IconButton, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import ConfirmBox from '../../functions/ConfirmBox';
import ErrorBox from '../../functions/ErrorBox';
import { ApiHelper as ApiHelperC } from '../../helpers';
import NavBar from '../../widget/NavBar';
import "./product.css";
import QueueIcon from '@material-ui/icons/Queue';

export default function Productmedia() {
    const [inforder, setinforder] = useState("d-none")
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const [confirm, setconfirm] = useState({
        open: false,
        agree: false
    })
    const [x, setx] = useState({
        alt: "",
        caption: "",
        image_name: "",
        storage: "",
        id: "",
        path: "",
        title: "",
    });
    const [image, setimage] = useState({})
    const [isNoneFlex, setisNoneFlex] = useState("flex");
    const [products, setproducts] = useState()
    const [loading, setloading] = useState(true)
    const [rightDrawer, setrightDrawer] = useState(false)
    const [displayO_C, setdisplayO_C] = useState("none")
    const [selectedProImg, setselectedProImg] = useState()
    const [did, setdid] = useState(0)
    const ApiHelper = new ApiHelperC()
    async function ProductListHandler() {
        const product = await ApiHelper.fetchMediaProducts()
        setproducts(product.data)
    }
    const imgDeleteHandler = (id) => {
        setconfirm({ ...confirm, open: true })
        setdid(id)
    }

    const deleting = async (id) => {
        const response = await ApiHelper.deleteProductMedia(id)
        if (response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: `Media_ID #${id}  successfully deleted in ${selectedProImg.name} gallary`, OC: true })
        }
        let AllGallaryImagesData = [...selectedProImg.gallary]
        const r = AllGallaryImagesData.findIndex((x) => x.id === id)
        AllGallaryImagesData.splice(r, 1)
        setselectedProImg({ ...selectedProImg, gallary: [...AllGallaryImagesData] })
        setconfirm({ open: false, agree: false })
    }
    useEffect(() => {
        if (confirm.agree) {
            if (did !== 0) {
                deleting(did)
            }
        }
        ProductListHandler()

        return () => {
            setproducts([])
        }
        // eslint-disable-next-line
    }, [confirm.agree, did])
    const fileUpload = async (e) => {
        const response = await ApiHelper.uploadImage(e.target.files, "product")
        setimage({
            ...response.data[0]
        })
        ProductListHandler()
    }
    const fileUploadMediaEdit = async (e) => {
        const response = await ApiHelper.uploadImage(e.target.files, "product")
        const data = response.data[0]
        setx({
            ...x,
            path: data.uri,
            storage: data.path,
            image_name: data.file_name
        })
        ProductListHandler()
    }


    function myloading() {
        setTimeout(function () { setloading(false); }, 2000);
    }
    const confirmMyFeatureImage = async () => {
        const createProductMedia = {
            "image": image.file_name,
            "path": image.path
        }

        const proid = selectedProImg.proid

        const response = await ApiHelper.addProductMedia(createProductMedia, proid)
        if (response.status === "success") {
            const dataImage = response.data[0]
            let insertImage = {
                id: dataImage.id,
                alt: dataImage.alt,
                image_name: dataImage.image,
                caption: dataImage.caption,
                title: dataImage.title,
                type: dataImage.type,
                storage: dataImage.storage,
                path: dataImage.path
            }
            setselectedProImg({ ...selectedProImg, gallary: [...selectedProImg.gallary, insertImage] })

            setimage({})

        }
    }

    const openfolderHandler = (pid) => {
        setdisplayO_C("flex")
        setisNoneFlex("none")
        setinforder("d-block")
        let b = products && (products.find((x) => x.proid === pid));
        setloading(true)
        setselectedProImg(b)
        myloading()
    }


    const imgEditHandler = (data) => {
        setx({ ...data })
        setrightDrawer(true)
    }
    const downaloadImg = async (id) => {
        const res = await ApiHelper.downloadProductMedia(id)
        const link = document.createElement("a")
        link.href = res
        link.click()
    }
    const marktoprimary = async (data) => {
        const mediaId = data.id
        const proid = selectedProImg.proid
        let imgArry = [...selectedProImg.gallary]
        const response = await ApiHelper.Marktopriamryproductmedia(mediaId, proid)
        if (response.status === "success") {
            setresponse_data({ status: response.status, message: `Media id # ${mediaId} set as a primary image in ${selectedProImg.name} gallary`, OC: true })
        }

        if (response.status === "success") {
            let index = imgArry.findIndex((x) => x.id === mediaId)
            imgArry[index].type = "main"
            imgArry.map((x, i) => {
                if (i !== index) {
                    x.type = "gallary"
                }
                return x
            })
            setselectedProImg({ ...selectedProImg, gallary: [...imgArry] })
        }
    }
    const EditSubmitHandler = async (id, type = "") => {
        const data = {
            image: x.image_name,
            path: x.storage,
            alt: x.alt,
            title: x.title,
            caption: x.caption,
            // proid : selectedProImg.proid
        }
        const response = await ApiHelper.EditProductmedia(data, id)
        if (response.status === "success") {
            const AllGallaryImagesData = [...selectedProImg.gallary]
            setselectedProImg({
                ...selectedProImg, gallary: [...AllGallaryImagesData.map((e, index) => {
                    if (e.id === id) {
                        e.image = data.image
                        e.path = x.path
                        e.storage = data.path
                        e.alt = data.alt
                        e.title = data.title
                        e.caption = data.caption
                    }
                    setresponse_data({ status: response.status, message: `Media id # ${id} upadated in ${selectedProImg.name} gallary`, OC: true })
                    return e;
                })]
            })
            setrightDrawer(false)
        }
    }
    document.title = `Product Media`
    return (
        <div>
            <NavBar />
            <ConfirmBox confirm={confirm} setconfirm={setconfirm}></ConfirmBox>
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Product Media
                        </Typography>
                        <Typography variant="subtitle1">
                            Click folder and edit media.
                        </Typography>
                    </div>
                </Box>
                <hr />
                <Box display={isNoneFlex} style={{ alignItems: "center" }}>
                    <div className="d-flex justify-content-start align-items-start flex-wrap">
                        {
                            products && (products.map((pro, i) => (
                                <div className="" key={i}>
                                    <div className="d-flex justify-content-start align-items-center ">
                                        <IconButton onClick={e => openfolderHandler(pro.proid)}>
                                            <FolderIcon color="action" style={{ fontSize: "5rem" }} />
                                        </IconButton>
                                            <div className="col-8 d-flex justify-content-center">
                                                <span type="button"  onClick={e => openfolderHandler(pro.proid)} style={{ width: "12rem", display: "block", margin: "0" }}>{pro.name}</span>
                                            </div>
                                    </div>
                                </div>
                            )))
                        }
                    </div>
                </Box>
                {
                    loading ? (
                        <div className="p-3" style={{ display: displayO_C }}>
                            <i className="fas fa-spinner fa-spin" style={{ color: "#3f51b5", fontSize: "2rem" }}> </i>
                            <span style={{ fontSize: "1.3rem", color: "black", marginTop: "0.4rem", marginLeft: "0.5rem" }}> Loading...</span>
                        </div>
                    ) :
                        (
                            <div className={inforder}>
                                <div className="back d-flex align-items-center">
                                    <IconButton aria-label="delete" onClick={() => { setinforder("d-none"); setisNoneFlex("flex") }}>
                                        <ArrowBackIosIcon fontSize="small" />
                                    </IconButton>
                                    <h6 className="m-0">{selectedProImg && selectedProImg.name}</h6>
                                </div>
                                <div className="mt-4 p-5">
                                    <div className="d-flex flex-wrap justify-content-center justify-content-md-start" style={{ columnGap: '2rem' }}>
                                        <div className="mb-5">

                                            {
                                                Object.keys(image).length > 0 ? (
                                                    <div style={{ border: "5px dashed gray", borderRadius: "0.3rem", width: "100%" }}>
                                                        <img src={image.uri} alt={image.file_name} width="202" height="186" />
                                                    </div>
                                                ) : (
                                                    <div style={{ border: "5px dashed gray", padding: "4rem 4.5rem 4rem 4.5rem", borderRadius: "0.3rem", width: "100%" }}>
                                                        <QueueIcon style={{ fontSize: "3rem", color: "gray" }} />
                                                    </div>

                                                )
                                            }
                                            {
                                                Object.keys(image).length > 0 ? (
                                                    <Button
                                                        style={{ width: '100%', margin: "1rem 0" }}
                                                        variant="contained"
                                                        component="label"
                                                        onClick={confirmMyFeatureImage}
                                                        color="primary"
                                                        className={`{selectedProImg && selectedProImg.gallary.length < 1 ? "emptyfolder-btn" : ""}`}

                                                    >
                                                        {"Confirm"}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        style={{ width: '100%', margin: "1rem 0" }}
                                                        variant="contained"
                                                        component="label"
                                                        color="primary"
                                                        className={selectedProImg && selectedProImg.gallary.length < 1 ? "emptyfolder-btn" : ""}
                                                    >
                                                        {"Add new image"}
                                                        <input
                                                            onChange={e => fileUpload(e)}
                                                            type="file"
                                                            hidden
                                                        />
                                                    </Button>
                                                )
                                            }
                                        </div>
                                        {
                                            selectedProImg && (selectedProImg.gallary.map((x) => (
                                                <div key={x.id} className="mb-5">
                                                    <ContextMenuTrigger id={`context_menu_${x.id}`}>
                                                        <img src={x.path} alt={x.alt} width="202" height="186" style={{ border: "1px solid black" }} />
                                                        <span className="badge bg-danger m-0" style={{ display: "block", right: "-0.5rem", position: "relative", top: "-11.2rem", fontSize: "0.9rem", width: "30%" }}>{x.type}</span>
                                                    </ContextMenuTrigger>
                                                    <ContextMenu id={`context_menu_${x.id}`} className="rigthClickcss">
                                                        <Paper elevation={3} className='p-2' >
                                                            <MenuItem onClick={e => { marktoprimary(x) }}>
                                                                Mark as primary
                                                            </MenuItem>
                                                            <MenuItem onClick={e => downaloadImg(x.id)}>
                                                                Download
                                                            </MenuItem>
                                                            <MenuItem onClick={e => imgEditHandler(x)}>
                                                                Edit media
                                                            </MenuItem>
                                                            {/* <MenuItem divider /> */}
                                                            <MenuItem onClick={e => imgDeleteHandler(x.id)}>
                                                                Delete
                                                            </MenuItem>
                                                        </Paper>
                                                    </ContextMenu>
                                                </div>
                                            )))
                                        }
                                    </div>
                                </div>
                                <Drawer id="MyDrawerProductmedia" anchor="right" open={rightDrawer} onClose={e => setrightDrawer(false)}>
                                    <div className="p-3" style={{ width: "25rem" }}>
                                        <Typography variant="h5">
                                            Edit Image #{x.id}
                                        </Typography>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12">
                                                <FormGroup>
                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <FormControl fullWidth variant="outlined">
                                                                <Typography variant="subtitle1" className="text-muted fw-bold">Title</Typography>
                                                                <TextField type="text" id="title" name="title" variant="outlined" value={x.title} placeholder="Title" onChange={(e) => setx({ ...x, title: e.target.value })} />
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <FormControl fullWidth variant="outlined">
                                                                <Typography variant="subtitle1" className="text-muted fw-bold">Alt</Typography>
                                                                <TextField type="text" id="alt" name="alt" variant="outlined" value={x.alt} placeholder="Alt" onChange={(e) => setx({ ...x, alt: e.target.value })} />
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <FormControl fullWidth variant="outlined">
                                                                <Typography variant="subtitle1" className="text-muted fw-bold">Caption</Typography>
                                                                <TextField type="text" id="caption" name="caption" variant="outlined" value={x.caption} placeholder="Caption" onChange={(e) => setx({ ...x, caption: e.target.value })} />
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img src={x.path} alt={x.path} width="150px" height="150px" />
                                                                <Button
                                                                    style={{ width: '100%', marginTop: "1rem", marginLeft: "1rem" }}
                                                                    variant="contained"
                                                                    component="label"
                                                                    color="primary"
                                                                >
                                                                    {"Change Image"}
                                                                    <input
                                                                        onChange={e => fileUploadMediaEdit(e)}
                                                                        type="file"
                                                                        hidden
                                                                    />
                                                                </Button>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <FormControl fullWidth variant="outlined">
                                                                <Typography variant="subtitle1" className="text-muted fw-bold">Path</Typography>
                                                                <TextField type="text" disabled id="path" name="path" variant="outlined" value={x.path} placeholder="Path" onChange={(e) => setx(e.target.value)} />
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-12 mb-4">
                                                            <Button onClick={e => EditSubmitHandler(x.id, selectedProImg.proid)} variant="contained" color="primary">
                                                                Submit
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </Drawer>
                            </div>
                        )

                    // (<><Box m={4} ml={10} display={displayO_C} >
                    //     <div className="row" style={{ position: "absolute", top: '12rem', left: "20rem" }}>
                    //         <div className="col-12">
                    //             <div>
                    //                 <IconButton onClick={() => { setdisplayO_C("none"); setisNoneFlex("flex") }}>
                    //                     <ArrowBackIosIcon />
                    //                 </IconButton>
                    //                 <h6 className="d-inline mt-0">
                    //                     {selectedProImg && (selectedProImg.name)}
                    //                 </h6>
                    //             </div>
                    //         </div>
                    //     </div>
                    //     <div className="row align-items-center mt-5 pt-5">
                    //         <div className="col-2 text-center " style={{ textJustify: "center", paddingTop: "1.5rem" }}>
                    //             {
                    //                 Object.keys(image).length > 0 ? (

                    //                     <img className="img-thumbnail" src={image.uri} alt={image.file_name} />
                    //                 ) : (

                    //                     <IconButton disabled className={selectedProImg && selectedProImg.gallary[0] && selectedProImg.gallary[0] && selectedProImg.gallary.length < 1 ? "emptyfolder" : ""} style={{ border: "5px dashed #737373", width: "100%", height: "100%", padding: "30% 0", borderRadius: "0.2rem", marginTop: "3rem" }} color="primary" aria-label="upload picture" component="span">
                    //                         <Icon className="fa fa-plus-circle" style={{ fontSize: 60, color: '#737373' }
                    //                         } />
                    //                     </IconButton>
                    //                 )
                    //             }
                    //             {
                    //                 Object.keys(image).length > 0 ? (
                    //                     <Button
                    //                         style={{ width: '100%', margin: "1rem 0" }}
                    //                         variant="contained"
                    //                         component="label"
                    //                         onClick={confirmMyFeatureImage}
                    //                         color="primary"
                    //                         className={selectedProImg && selectedProImg.gallary.length < 1 ? "emptyfolder-btn" : ""}

                    //                     >
                    //                         {"Confirm"}
                    //                     </Button>
                    //                 ) : (
                    //                     <Button
                    //                         style={{ width: '100%', margin: "2rem 0" }}
                    //                         variant="contained"
                    //                         component="label"
                    //                         color="primary"
                    //                         className={selectedProImg && selectedProImg.gallary.length < 1 ? "emptyfolder-btn" : ""}
                    //                     >
                    //                         {"Add new image"}
                    //                         <input
                    //                             onChange={e => fileUpload(e)}
                    //                             type="file"
                    //                             hidden
                    //                         />
                    //                     </Button>
                    //                 )
                    //             }
                    //         </div>
                    //         {
                    //             selectedProImg && (selectedProImg.gallary.map((x) => (
                    //                 <div key={x.id} className="col-2">
                    //                     <ContextMenuTrigger id={`context_menu_${x.id}`}>
                    //                         <div className="position-relative">
                    //                             <img className="img-thumbnail" src={x.path} alt={x.alt} />
                    //                             <span className="badge bg-danger position-absolute m-3" style={{ right: 0 }}>{x.type}</span>
                    //                         </div>
                    //                     </ContextMenuTrigger>
                    //                     <ContextMenu id={`context_menu_${x.id}`} className="rigthClickcss">
                    //                         <Paper elevation={3} className='p-2' >
                    //                             <MenuItem onClick={e => { marktoprimary(x) }}>
                    //                                 Mark as primary
                    //                             </MenuItem>
                    //                             <MenuItem onClick={e => downaloadImg(x.id)}>
                    //                                 Download
                    //                             </MenuItem>
                    //                             <MenuItem onClick={e => imgEditHandler(x)}>
                    //                                 Edit media
                    //                             </MenuItem>
                    //                             <MenuItem divider />
                    //                             <MenuItem onClick={e => imgDeleteHandler(x.id)}>
                    //                                 Delete
                    //                             </MenuItem>
                    //                         </Paper>
                    //                     </ContextMenu>
                    //                 </div>
                    //             )))
                    //         }
                    //     </div>
                    // </Box>
                    //     <Drawer id="MyDrawerProductmedia" anchor="right" open={rightDrawer} onClose={e => setrightDrawer(false)}>
                    //         <div className="p-3" style={{ width: "25rem" }}>
                    //             <Typography variant="h5">
                    //                 Edit Image #{x.id}
                    //             </Typography>
                    //             <hr />
                    //             <div className="row">
                    //                 <div className="col-12">
                    //                     <FormGroup>
                    //                         <div className="row">
                    //                             <div className="col-12 mb-4">
                    //                                 <FormControl fullWidth variant="outlined">
                    //                                     <Typography variant="subtitle1" className="text-muted fw-bold">Title</Typography>
                    //                                     <TextField type="text" id="title" name="title" variant="outlined" value={x.title} placeholder="Title" onChange={(e) => setx({ ...x, title: e.target.value })} />
                    //                                 </FormControl>
                    //                             </div>
                    //                             <div className="col-12 mb-4">
                    //                                 <FormControl fullWidth variant="outlined">
                    //                                     <Typography variant="subtitle1" className="text-muted fw-bold">Alt</Typography>
                    //                                     <TextField type="text" id="alt" name="alt" variant="outlined" value={x.alt} placeholder="Alt" onChange={(e) => setx({ ...x, alt: e.target.value })} />
                    //                                 </FormControl>
                    //                             </div>
                    //                             <div className="col-12 mb-4">
                    //                                 <FormControl fullWidth variant="outlined">
                    //                                     <Typography variant="subtitle1" className="text-muted fw-bold">Caption</Typography>
                    //                                     <TextField type="text" id="caption" name="caption" variant="outlined" value={x.caption} placeholder="Caption" onChange={(e) => setx({ ...x, caption: e.target.value })} />
                    //                                 </FormControl>
                    //                             </div>
                    //                             <div className="col-12">
                    //                                 <div className="d-flex align-items-center justify-content-center">
                    //                                     <img src={x.path} alt={x.path} width="150px" height="150px" />
                    //                                     <Button
                    //                                         style={{ width: '100%', marginTop: "1rem", marginLeft: "1rem" }}
                    //                                         variant="contained"
                    //                                         component="label"
                    //                                         color="primary"
                    //                                     >
                    //                                         {"Change Image"}
                    //                                         <input
                    //                                             onChange={e => fileUploadMediaEdit(e)}
                    //                                             type="file"
                    //                                             hidden
                    //                                         />
                    //                                     </Button>
                    //                                 </div>

                    //                             </div>
                    //                             <div className="col-12 mb-4">
                    //                                 <FormControl fullWidth variant="outlined">
                    //                                     <Typography variant="subtitle1" className="text-muted fw-bold">Path</Typography>
                    //                                     <TextField type="text" disabled id="path" name="path" variant="outlined" value={x.path} placeholder="Path" onChange={(e) => setx(e.target.value)} />
                    //                                 </FormControl>
                    //                             </div>
                    //                             <div className="col-12 mb-4">
                    //                                 <Button onClick={e => EditSubmitHandler(x.id, selectedProImg.proid)} variant="contained" color="primary">
                    //                                     Submit
                    //                                 </Button>
                    //                             </div>

                    //                         </div>
                    //                     </FormGroup>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </Drawer>
                    // </>
                    // )
                }
            </div>
        </div >
    )
}
