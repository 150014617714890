import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DataGrid } from "@material-ui/data-grid";
import { EditOutlined } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import ErrorBox from "../../functions/ErrorBox";
import FormBasedDialog from "../../functions/FormBasedDialog";
import { ApiHelper as ApiHelperc } from "../../helpers";
import NavBar from "../../widget/NavBar";
import EditfaqModel from "./EditfaqModel";

export default function Faqs() {
  const [editState, setEditState] = useState();
  const [OpenEditModel, setOpenEditModel] = useState(false);
  const [response_data, setresponse_data] = useState({
    status: "",
    message: "",
    OC: false,
    redirect_to: "",
  });
  const faqRef = useRef({
    faq_qus: "",
    faq_ans: "",
    status: "0",
  });

  const DeletFaqHandler = async(id) => {
    // const response = await ApiHelper.RemoveFaq(id)
    console.log(id)
  }

  const [dataRows, setDataRows] = useState([]);
  const ApiHelper = new ApiHelperc();
  const columns = [
    { field: "userid", headerName: "Username", width: 150 },
    { field: "faq_qus", headerName: "Question", width: 150 },
    { field: "faq_ans", headerName: "Answer", width: 200 },
    { field: "status", type: "boolean", headerName: "Publish", width: 140 },
    { field: "created_at", headerName: "Created At", width: 140 },
    {
      field: "",
      headerName: "Actions",
      width: 140,
      type: "datetime",
      renderCell: (cell) => {
        return (
          <>
            <IconButton
              color="secondary"
              onClick={(e) => {setEditState({ faq_qus: cell.row.faq_qus,
              faq_ans: cell.row.faq_ans,
              status: cell.row.status,
              faqid:cell.row.faqid});setOpenEditModel(true)}}
            >
              <EditOutlined />
            </IconButton>
            <IconButton color="secondary" onClick={(e) => DeletFaqHandler(cell.row.faqid)}>
            <DeleteOutlineIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const fetchFaq = async () => {
    const response = await ApiHelper.fetchFaq();
    setDataRows([...response.data]);
  };

  const SaveFaqs = async (e) => {
    for (let fild in faqRef.current) {
      if (faqRef.current[fild] === "" || faqRef.current[fild] === "undefined") {
        setresponse_data({
          ...response_data,
          status: "Validation Error",
          message: `Rquired field ${fild} is empty`,
          OC: true,
        });
      }
    }
    let result = await ApiHelper.createFaq(faqRef.current);
    if (result?.status === "success") {
      setresponse_data({
        ...response_data,
        status: result.status,
        message: `New FAQ successfully saved`,
        OC: true,
      });
      faqRef.current = {
        faq_qus: "",
        faq_ans: "",
        status: "0",
      };
      fetchFaq();
    }
  };
  useEffect(() => {
    fetchFaq();
    return () => {
      setDataRows([]);
    };
    // eslint-disable-next-line
  }, []);
  document.title = `FAQs`;
  return (
    <div>
      <NavBar />
      <ErrorBox
        setresponse_data={setresponse_data}
        response_data={response_data}
      ></ErrorBox>
      <div className="text-start small-d">
        <Box mx={4} my={2} style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexGrow: "1" }}>
            <Typography variant="h5">Add Frequently Ask Question</Typography>
            <Typography variant="subtitle1">
              Add new Frequently Ask Question into the system.
            </Typography>
          </div>
        </Box>
        <hr />
        <div className="row m-0">
          <div className="col-lg-4 col-md-5 col-sm-12">
            <Box m={4}>
              <FormControl
                className="mt-4"
                style={{ width: "100%" }}
                variant="outlined"
              >
                <TextField
                  id="faq_qus"
                  fullWidth
                  multiline={true}
                  rows={5}
                  onChange={(e) => (faqRef.current.faq_qus = e.target.value)}
                  variant="outlined"
                  placeholder="Question"
                />
              </FormControl>
              <FormControl
                className="mt-4"
                style={{ width: "100%" }}
                variant="outlined"
              >
                <TextField
                  id="faq_ans"
                  onChange={(e) => (faqRef.current.faq_ans = e.target.value)}
                  variant="outlined"
                  placeholder="Answer"
                  fullWidth
                  multiline={true}
                  rows={5}
                />
              </FormControl>
              <FormControl component="fieldset" className="mt-4">
                <Typography variant="subtitle1" className="text-muted fw-bold">
                  Publish Status
                </Typography>
                <RadioGroup
                  aria-label="publish_status"
                  name="publish_status"
                  defaultValue="0"
                  onChange={(e) => (faqRef.current.status = e.target.value)}
                >
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className="mt-3 w-100">
                <Button
                  variant="outlined"
                  className="w-100"
                  color="primary"
                  onClick={SaveFaqs}
                >
                  Save
                </Button>
              </FormControl>
            </Box>
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12">
            <Box m={4}>
              <DataGrid
                loading={""}
                autoHeight={true}
                rows={dataRows}
                getRowId={(e) => {
                  return e.id;
                }}
                columns={columns}
                pageSize={10}
              />
            </Box>
          </div>
        </div>
      </div>
      <FormBasedDialog
        status={{ state: setOpenEditModel, isOpen: OpenEditModel }}
        title={
          <>
            <Typography variant="h6">
              Edit FAQ id #<b>{editState && editState.faqid}</b>
            </Typography>
            <Typography variant="subtitle2"></Typography>
          </>
        }
        component={
          <EditfaqModel
            editState={editState}
            setEditState={setEditState}
            // OpenEditModel={OpenEditModel}
            setOpenEditModel={setOpenEditModel}
          ></EditfaqModel>
        }
      />
    </div>
  );
}
