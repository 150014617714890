import { Box, Card, CardContent, Divider, Typography, IconButton, Tooltip } from '@material-ui/core';
import { RemoveCircleOutlineOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ApiHelper as ApiHelperC } from '../../helpers';
import NavBar from '../../widget/NavBar';
import { useSnackbar } from 'notistack'

export default function TrendingProduct() {
    const ApiHelper = new ApiHelperC()
    const [Startindex, setStartindex] = useState("")
    const [dataRows, setDataRows] = useState([])
    const snackbar = useSnackbar()
    const showSnackbar = (message) => {
        snackbar.enqueueSnackbar(message)
    }
    async function fetchProducts() {
        const result = await ApiHelper.fetchTrendingProducts()
        // console.log(result)
        if (result.status === "success") {
            // console.log(result.data)
            setDataRows([...result.data])
        }
    }

    const onDragStart = result => {
        setStartindex(result.source.index)
    }

    const onDragEnd = async (result) => {
        const ENDINGINDEX = result.destination?.index ?? null
        if (typeof ENDINGINDEX !== "object") {

            const tempContent = [...dataRows]

            const UNQSTARTID = tempContent[Startindex].id
            const UNQENDID = tempContent[ENDINGINDEX].id

            const TABSTARTIDEX = tempContent[Startindex].tindex
            const TABENDIDEX = tempContent[ENDINGINDEX].tindex

            const respones = await ApiHelper.updateTredingProduct({
                UNQSTARTID, UNQENDID, TABSTARTIDEX, TABENDIDEX
            })

            if (respones.status === "success") {
                const onStartContnet = tempContent[Startindex]
                const onEndContnet = tempContent[ENDINGINDEX]
                tempContent[ENDINGINDEX] = onStartContnet
                tempContent[Startindex] = onEndContnet
                fetchProducts()
            }
        } else { console.log("Destination Found") }
    }

    const removeFromTrending = async (id) => {
        const response = await ApiHelper.RemoveTrending(id)
        if (response.success) {
            window.location.reload()
        } else {
            showSnackbar(response.message)
        }
    }

    useEffect(() => {
        fetchProducts()
        return () => {
            setDataRows([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    document.title = `Trending Products`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2}>
                    <Typography variant="h5">
                        All Trending Product
                    </Typography>
                    <Typography variant="subtitle1">
                        View all Trending Products.
                    </Typography>
                </Box>
                <Divider />
                {/* <Box m={4}>
                    <DataGrid loading={isLoading} autoHeight={true} rows={dataRows} getRowId={e => { return e.id }} columns={columns} pageSize={10} />
                </Box> */}
                <Box m={2} className="tranding-width">
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} >
                        <Droppable droppableId="droppable">
                            {provided => (
                                <div ref={provided.innerRef}>
                                    {dataRows.map((c, index) => (
                                        <Draggable key={c.id} draggableId={c.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <Card className="mt-4" ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <CardContent >
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-100">
                                                                <Typography variant="h6" className="me-4">
                                                                    <b>#{index + 1}</b>
                                                                </Typography>
                                                                <div>
                                                                    <Typography variant="h6">
                                                                        {c.name}
                                                                    </Typography>
                                                                    <Typography variant="subtitle1" color="error">
                                                                        {c.category}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                            <div className="ms-2">
                                                                <Tooltip title={`Remove (${c.name}) from trending products`}>
                                                                    <IconButton onClick={_ => removeFromTrending(c.id)}>
                                                                        <RemoveCircleOutlineOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </div>
        </div>
    )
}
