import { Container } from '@material-ui/core';
import React from 'react'
import NavBar from '../../widget/NavBar'

export default function DashboardComponent() {
    // const classes = useStyles();
    document.title = `Ayurveda Rajan Deshbord`
    return (
        <>
            <NavBar />
            <Container maxWidth="lg" className="container mt-5 pt-5">
                
            </Container>
        </>
    )
}
