import { Box, Button, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import React, { useEffect, useRef, useState } from 'react'
import ErrorBox from '../../functions/ErrorBox'
import { ApiHelper as ApiHelperC } from '../../helpers'
import NavBar from '../../widget/NavBar'

export default function Editproduct(props) {
    let proid = props.match.params.id
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const [categorySelect, setcategorySelect] = useState()
    const editerDescription = useRef()
    const editerBenefits = useRef()
    const editerIngrediants = useRef();
    const editorShort_content = useRef(null);
    // const [productInfo, setproductInfo] = useState({
    //     name: "",
    //     content: "",
    //     product_benefit: "",
    //     product_ingrediant: "",
    //     category: "",
    //     product_permalink: "",
    //     short_content: "",
    //     sku: "",
    //     price: "",
    //     regular_price: "",
    //     quantity: "",
    //     discount_for_online: "",
    //     tags: "",
    //     stock: "true",
    //     suppliment_for: "",
    //     meta_title: "",
    //     meta_description: ""
    // })
    const [CSG ,setCSG] = useState({
        stock:"",
        suppliment_for: "",
        category: ""
    })
    const productInfo = useRef({
        name: "",
        content: "",
        product_benefit: "",
        product_ingrediant: "",
        product_permalink: "",
        short_content: "",
        sku: "",
        price: "",
        regular_price: "",
        quantity: "",
        discount_for_online: "",
        tags: "",
        meta_title: "",
        meta_description: ""
    })
    const setValues = () => {
        for(let keys in productInfo.current){
            let inputs = document.getElementById(keys)
            if(inputs){
                if(inputs !== null && inputs !== "null"){
                    if(inputs.id === keys){
                        inputs.value = productInfo.current[keys]
                        // console.log(inputs.value)
                    }
                }
            }
        }
    }
    setValues()
    document.title = `Edit Product ${productInfo.current.name}`
    const ApiHelper = new ApiHelperC()
    async function fetchCategory() {
        const response = await ApiHelper.fetchPostCategory()
        setcategorySelect([...response.data])
    }
    async function EditProductdetails() {
        const response = await ApiHelper.fetchEditPorduct(proid)
        // console.log(response)
        setCSG({...CSG,category:response.data.category,suppliment_for:response.data.suppliment_for,stock:response.data.stock === 1 ? "true" : "false"})
        let data = {
            name: response.data.name,
            content: response.data.content,
            product_benefit: response.data.product_benefit,
            product_ingrediant: response.data.product_ingrediant,
            product_permalink: response.data.product_permalink,
            sku: response.data.sku,
            short_content: response.data.short_content,
            price: response.data.price,
            regular_price: response.data.regular_price,
            quantity: response.data.quantity,
            discount_for_online: response.data.discount_for_online,
            tags: response.data.tags,
            meta_title: response.data.meta_title,
            meta_description: response.data.meta_description
        }
        productInfo.current = { ...productInfo.current, ...data }
    }

    // console.log(productInfo.name)
    useEffect(() => {
        EditProductdetails()
        fetchCategory()
        return () => {
            setcategorySelect([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const updateSubmitHandler = async () => {
        let data = {
            ...productInfo.current,
            ...CSG,
            content: editerDescription.current.getContent(),
            product_benefit: editerBenefits.current.getContent(),
            product_ingrediant: editerIngrediants.current.getContent(),
            short_content: editorShort_content.current.getContent()
        }
        for (let property in data) {
            if (data[property] === undefined || data[property] === "" || data[property].length <= 0) {
                return setresponse_data({ ...response_data, status: "Validation Error", message: `Required ${property} field is Empty`, OC: true })
            }
        }
        const response = await ApiHelper.UpdateProduct(proid, data)
        if (response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: `ID #${proid} ${response.message}`, OC: true })

        }
    }

    // console.log(productInfo.current)
    let paddingL = window.innerWidth && (window.innerWidth >= 992 ? "1.7rem" : "0.8rem")
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Edit Product
                        </Typography>
                        <Typography variant="subtitle1">
                            Edit Product #{proid} to the system.
                        </Typography>
                    </div>
                    <div>
                        <Button variant="outlined" className="ms-3" color="primary" onClick={(e) => { updateSubmitHandler() }}>Publish</Button>
                    </div>
                </Box>
                <hr />

                <div className="row mx-0">
                    <div className="col-12 p-0">
                        <Box m={4} mx={0}>
                            <h2 className="mb-4 mx-4">Edit Product Details</h2>
                            <FormGroup>
                                <div className="row mx-0 w-100">
                                    <div className="col-12">
                                        <div className="row mx-0">
                                            <div className="col-lg-8 col-sm-12 mb-3">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Product Title</Typography>
                                                    <TextField type="text" name="title" id="name" variant="outlined" placeholder="Enter Product Name"
                                                        // value={productInfo.name}
                                                        onChange={(e) => productInfo.current.name = e.target.value}
                                                    // onChange={(e) => name.current = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 mt-1 mb-4" style={{ paddingLeft: paddingL }}>
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-1">Product Category</Typography>
                                                    <Select
                                                        // value={productInfo.category}
                                                        native
                                                        name="type"
                                                        value={CSG.category}
                                                        onChange={(e) => setCSG(old => {return {...old,category:e.target.value}})}
                                                        label="Select category"
                                                        inputProps={{
                                                            name: 'category',
                                                            // id: 'outlined-age-native-simple',
                                                        }}
                                                    >
                                                        <option value="" disabled>Choose Category</option>
                                                        {
                                                            categorySelect && categorySelect.map((x) => (
                                                                <option value={x.catid} key={x.catid}>{x.title}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-8">
                                        <div className="row mx-0">
                                            <div className="col-12 mb-4">
                                                <Typography variant="subtitle1" className="text-muted fw-bold mb-1">Product Description</Typography>
                                                <Editor
                                                    onInit={(evt, editor) => editerDescription.current = editor}
                                                    initialValue={productInfo.current.content}
                                                    apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                                        content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    }}
                                                />

                                            </div>
                                            <div className="col-12 mb-4">
                                                <Typography variant="subtitle1" className="text-muted fw-bold mb-1">Product Benefits</Typography>
                                                <Editor
                                                    onInit={(evt, editor) => editerBenefits.current = editor}
                                                    initialValue={productInfo.current.product_benefit}
                                                    apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                                        content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 mb-4" >
                                                <Typography variant="subtitle1" className="text-muted fw-bold mb-1">Product Ingrediants</Typography>
                                                <Editor
                                                    onInit={(evt, editor) => editerIngrediants.current = editor}
                                                    initialValue={productInfo.current.product_ingrediant}
                                                    apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                                        content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="row mx-0">
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Product Permalink</Typography>
                                                    <TextField type="text" name="product_permalink" id="product_permalink" variant="outlined" placeholder="Enter Product Permalink"
                                                        // value={productInfo.product_permalink}
                                                        onChange={(e) => productInfo.current.product_permalink = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Product SKU</Typography>
                                                    <TextField type="text" name="sku" id="sku" variant="outlined" placeholder="Enter SKU"
                                                        // value={productInfo.sku}
                                                        onChange={(e) => productInfo.current.sku = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Product Shelling Price</Typography>
                                                    <TextField type="number" name="sales_price" id="price" variant="outlined" placeholder="Enter Shelling Price (₹)"
                                                        onChange={(e) => productInfo.current.price = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Product Regular Price</Typography>
                                                    <TextField type="number" name="reguler_price" id="regular_price" variant="outlined" placeholder="Enter Regular Price (₹)"
                                                        onChange={(e) => productInfo.current.regular_price = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Product Stock</Typography>
                                                    <TextField type="number" name="quantity" id="quantity" variant="outlined" placeholder="Enter Stock"
                                                        onChange={(e) => productInfo.current.quantity = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Online Discount</Typography>
                                                    <TextField type="number" name="discount_for_online" id="discount_for_online" variant="outlined" placeholder="Enter Discount (%)"
                                                        onChange={(e) => productInfo.current.discount_for_online = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Meta Tags</Typography>
                                                    <TextField type="text" name="tags" id="tags" variant="outlined" placeholder="Enter Meta Tags (seperated by comama ',')"
                                                        // value={productInfo.tags}
                                                        onChange={(e) => productInfo.current.tags = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Meta Title</Typography>
                                                    <TextField type="text" name="meta_title" id="meta_title" variant="outlined" placeholder="Enter Meta Title"
                                                        onChange={(e) => productInfo.current.meta_title = e.target.value}
                                                    ></TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl fullWidth variant="outlined">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold mb-2">Meta Description</Typography>
                                                    <TextField fullWidth multiline rowsMax={5} type="text" name="meta_description" rows={5} id="meta_description" variant="outlined" placeholder="Enter Meta Description"
                                                        onChange={(e) => productInfo.current.meta_description = e.target.value}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-12">
                                                <FormControl component="fieldset" className="mt-4">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold">Stock Status</Typography>
                                                    <RadioGroup aria-label="stock_status" name="stock" value={CSG.stock}
                                                        onChange={(e) => setCSG({...CSG, stock: e.target.value})}                                                         >
                                                        <FormControlLabel value={"true"} control={<Radio />} label="Yes"  />
                                                        <FormControlLabel value={"false"} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <FormControl component="fieldset" className="mt-4">
                                                    <Typography variant="subtitle1" className="text-muted fw-bold">Suppliment For</Typography>
                                                    <RadioGroup aria-label="suppliment_for" name="suppliment_for" value={CSG.suppliment_for}
                                                        onChange={(e) => setCSG({...CSG, suppliment_for: e.target.value})} 
                                                    >
                                                        <FormControlLabel value={"male"} control={<Radio />} label="Male" />
                                                        <FormControlLabel value={"female"} control={<Radio />} label="Female" />
                                                        <FormControlLabel value={"both"} control={<Radio />} label="Both" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className="col-12">
                                                <Typography variant="subtitle1" className="text-muted fw-bold">Product Short Content</Typography>
                                                <Editor
                                                    onInit={(evt, editor) => editorShort_content.current = editor}
                                                    initialValue={productInfo.current.short_content}
                                                    apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                                                    init={{
                                                        height: 500,
                                                        max_height: 160,
                                                        menubar: false,
                                                        plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                                                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                                                        content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                        images_upload_handler: async (blobInfo, success, failure, _) => {
                                                            const file = blobInfo.blob()
                                                            const tinyMcResult = await ApiHelper.uploadImage({ image: file }, "product")
                                                            if (tinyMcResult !== "") {
                                                                success(tinyMcResult.data[0].uri)
                                                            } else {
                                                                failure('Something Went Wrong')
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </Box>
                        {/* <hr style={{ width: "97%", margin: "0 auto" }} /> */}
                    </div>
                </div>
            </div>
        </div >
    )
}
