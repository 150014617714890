import React, { useEffect, useState } from 'react'
import { Box, Typography, Divider, IconButton } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid';
import NavBar from '../../widget/NavBar';
import { ApiHelper as ApiHelperC } from '../../helpers';
import { EditOutlined, TrendingUp } from '@material-ui/icons';
import ErrorBox from '../../functions/ErrorBox';
import { Link } from 'react-router-dom';

export default function AllProducts(props) {
    const ApiHelper = new ApiHelperC()
    const [isLoading, setIsLoading] = useState(true)
    const [dataRows, setDataRows] = useState([])
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: ""
    })
    const AddTrending = async (proid) => {
        const response = await ApiHelper.AddTrending(proid)
        if (response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: `ID #${proid} ${response.message}`, OC: true })
        } else {
            setresponse_data({ ...response_data, status: response.status, message: `ID #${proid} ${response.message}`, OC: true })
        }
    }
    const columns = [
        { field: 'name', headerName: 'Product Title', width: 300 },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'product_permalink', headerName: 'Product URL', width: 170 },
        { field: 'stock', headerName: 'Stock', type: 'boolean', width: 150 },
        { field: 'created_at', headerName: 'Uploaded At', type: 'dateTime', width: 250, },
        {
            field: 'proid', headerName: 'Action', type: 'dateTime', width: 250, renderCell: (cell) =>
                <>
                    <IconButton color="primary" onClick={e => { AddTrending(cell.value) }}>
                        <TrendingUp />
                    </IconButton>
                    <Link to={`/product/edit/${cell.value}`}>
                        <IconButton color="secondary">
                            <EditOutlined />
                        </IconButton>

                    </Link>
                </>
        },
    ];

    async function fetchProducts() {
        const result = await ApiHelper.fetchProducts()
        if (result.status === "success") {
            setIsLoading(false)
            // console.log(result.data)
            setDataRows([...result.data])
        }
    }

    useEffect(() => {
        fetchProducts()
        return () => {
            setIsLoading(true);
            setDataRows([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    document.title = `View All Products`
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} my={2}>
                    <Typography variant="h5">
                        All Products
                    </Typography>
                    <Typography variant="subtitle1">
                        View all Products.
                    </Typography>
                </Box>
                <Divider />
                <Box m={4}>
                    <DataGrid loading={isLoading} autoHeight={true} rows={dataRows} getRowId={e => { return e.id }} columns={columns} pageSize={10} />
                </Box>
            </div>
        </div>
    )
}
