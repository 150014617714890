import { Box, Typography, IconButton } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import React, { useEffect, useState } from 'react'
import NavBar from '../../widget/NavBar'
import { ApiHelper as ApiHelperC } from '../../helpers'
import { EditOutlined } from '@material-ui/icons'
import { Link } from 'react-router-dom'

export default function Dispatch() {
    const [row, setrow] = useState([])
    const status = "dispatch"
    const ApiHelper = new ApiHelperC()
    const getdata = async () => {
        let data = await ApiHelper.getrejected(status)
        setrow(data);
    }
    useEffect(() => {
        getdata()// eslint-disable-next-line
    }, [])
    const columns = [
        { field: "orderid", headerName: "Order Id #", width: 150 },
        { field: "full_name", headerName: "Name", width: 150 },
        { field: "email", headerName: "Email", width: 150 },
        { field: "phone", headerName: "Phone", width: 150 },
        { field: "state", headerName: "Status", width: 150 },
        { field: "district", headerName: "City", width: 150 },
        { field: "payment_status", headerName: "Payment Status", width: 150 },
        { field: "type", headerName: "Payment Method", width: 150 },
        { field: "total_price", headerName: "Total Price", width: 150 },
        { field: "crmid", headerName: "CRM Id", width: 150 },
        { field: "courirer_service", headerName: "Courirer Service", width: 150 },
        { field: "tracking_id", headerName: "Tracking Id", width: 150 },
        { field: "created_at", headerName: "Order At", width: 150 },
        {
            field: "", headerName: "Action", width: 150,
            renderCell: (params) => (
                <Link to={`/order/edit/${params.row.orderid}`}>
                    <IconButton color="primary">
                        <EditOutlined />
                    </IconButton>
                </Link>
            )
        },
    ]
    document.title = `Dispatched Oreders`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Dispatch Status
                        </Typography>
                        <Typography variant="subtitle1">
                            Dispatched order details.
                        </Typography>
                    </div>
                </Box>
                <hr />
                <div className="row">
                    <div className="col-12 p-0">
                        <Box m={4} mb={0}>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    rows={row.length > 0 ? row : []}
                                    autoHeight
                                    getRowId={e => e.orderid}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}
