import { Box, Button, FormControl, Select, TextField, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useRef, useState } from "react";
import ErrorBox from "../../functions/ErrorBox";
import { ApiHelper as ApiHelperc } from "../../helpers";
import NavBar from "../../widget/NavBar";

export default function SocailShare() {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const [socialApPData, setsocialApPData] = useState({
        link_type: "",
        image: "",
        type: "",
    })
    const socialRef = useRef({
        link_url:""
    })
    const [dataRows, setDataRows] = useState([])
    const ApiHelper = new ApiHelperc()
    const [featureImage, setFeatureImage] = useState({})
    const columns = [
        { field: 'image', headerName: 'Icon', width: 150, renderCell: (image) => (<img  alt={image.value}  src={image.value} className="img-fluid" height={30} width={30} />) },
        { field: 'link_type', headerName: 'Link Type', width: 150, },
        { field: 'type', headerName: 'Type', width: 150,},
        { field: 'link_url', headerName: 'Url', width: 150,renderCell: (href) => (<a href={href.value} className="img-fluid" height={30} width={30}>{href.value}</a>) },
    ];




    const fetchSocialUrl = async () => {
        const response = await ApiHelper.fetchSocialUrl()
        setDataRows([...response.data])
    }
    const fileUpload = async (image) => {
        let result = await ApiHelper.uploadImage(image, "social")
        if (result !== "" && result !== "undefined") {
            if(result.status === "success"){
                setresponse_data({ ...response_data, status:result && result.status && result.status, message: `Image uploaded successfully`, OC: true })
            }
            result = result.data
            if (result.length > 0) {
                setsocialApPData({ ...socialApPData, image: result[0].path })
                setFeatureImage({ ...result[0] })
            } else {
                alert("Server Refuse to upload file")
            }
        }
    }
    const SaveSocialPlaft = async (e) => {
        for(let fild in socialApPData){
            if(socialApPData[fild] === "" || socialApPData[fild] === "undefined"){
               return setresponse_data({ ...response_data, status: "Validation Error", message: `Rquired field ${fild} is empty`, OC: true })
            }
        }
        if(socialRef.current === "" || socialRef.current === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Rquired field URL is empty`, OC: true })
        }
        let result = await ApiHelper.createSocial({...socialApPData,link_url: socialRef.current.link_url})
        if (result.status === "success") {
            setresponse_data({ ...response_data, status:result && result.status && result.status, message: `New social link Uploaded`, OC: true })
            setsocialApPData({
                link_type: "",
                image: "",
                type: "",
            })
            socialRef.current.link_url = ""
            setFeatureImage({})
            fetchSocialUrl()
        }
    }
    useEffect(() => {
        fetchSocialUrl()
        return () => {
            setDataRows([])
        }
        // eslint-disable-next-line
    }, [])
    document.title = `Social`
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} my={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Add Social Link
                        </Typography>
                        <Typography variant="subtitle1">
                            Add new Appereance into the system.
                        </Typography>
                    </div>

                </Box>
                <hr />
                <div className="row m-0">
                    <div className="col-md-6 col-lg-5 col-sm-12">
                        <Box m={4}>
                            <div className="mb-4 text-left">
                                <div style={{ backgroundImage: `url(${featureImage.uri ?? ""})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100%", height: 200, borderWidth: "1px", borderStyle: "double" }}>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <Button
                                            style={{ width: '100%' }}
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                        >
                                            {"Upload Gllary"}
                                            <input
                                                onChange={e => { fileUpload(e.target.files) }}
                                                type="file"
                                                hidden
                                                multiple
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <Select
                                    native
                                    value={socialApPData.slider_type}
                                    onChange={e => { setsocialApPData({ ...socialApPData, link_type: e.target.value }) }}
                                    label="Select Link Type"
                                    inputProps={{
                                        name: 'linktype',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="">
                                        Choose Link Type
                                    </option>
                                    {
                                        ['facebook', 'instagram', 'linkedin', 'snapchat', 'pintrest', 'youtube', 'twitter', 'whatsapp'].map((e, i) => (
                                            <option key={i} aria-label="None" value={e}>
                                                {e.replaceAll("_", " ").toUpperCase()}
                                            </option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <Select
                                    native
                                    value={socialApPData.slider_type}
                                    onChange={e => { setsocialApPData({ ...socialApPData, type: e.target.value }) }}
                                    label="Select  Type"
                                    inputProps={{
                                        name: 'type',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" disabled value="">
                                        Choose  Type
                                    </option>
                                    {
                                        ['sharer', 'page'].map((e, i) => (
                                            <option key={i} aria-label="None" value={e}>
                                                {e.replaceAll("_", " ").toUpperCase()}
                                            </option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                <TextField id="link_url" onChange={(e) => socialRef.current.link_url = e.target.value } variant="outlined" placeholder="SHARE URL" />
                            </FormControl>
                            <FormControl className="mt-3 w-100">
                                <Button variant="outlined" className="w-100" color="primary" onClick={SaveSocialPlaft} >Save</Button>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="col-md-6 col-lg-7 col-sm-12">
                        <Box m={4}>
                            <DataGrid loading={""} autoHeight={true} rows={dataRows} getRowId={e => { return e.id }} columns={columns} pageSize={10} />
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}