import { Box, Button, Divider, IconButton, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { EditOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import AddReviewForm from '../../functions/AddReviewForm';
import FormBasedDialog from '../../functions/FormBasedDialog';
import { ApiHelper as ApiHelperC } from '../../helpers';
import NavBar from '../../widget/NavBar';
import EditReviews from './EditReviews';

const ApiHelper = new ApiHelperC()


export default function ReviewProduct() {
    const [AddreviewModal, setAddreviewModal] = useState(false)
    const [EditReviewModal, setEditReviewModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [dataRows, setDataRows] = useState([])
    const [EditReviewData, setEditReviewData] = useState()

    const visibilityOffOn = async (id) => {
        const response = await ApiHelper.updateReview(id)
        if (response.status === "success") {
            fetchProductsReview()
        }
    }
    const EditComment = (data) => {
        setEditReviewModal(true)
        setEditReviewData(data)
    }
    const columns = [
        { field: 'name', headerName: 'Product Name', width: 400, flex: 1 },
        { field: 'message', headerName: 'Product Comment', width: 200 },
        { field: 'customer', headerName: 'Customer', width: 140 },
        { field: 'rating', headerName: 'Rating', width: 140 },
        { field: 'comment_stst', headerName: 'Status', type: "boolean", width: 140 },
        { field: 'created_at', headerName: 'created At', type: 'dateTime', width: 250, },
        {
            field: '', headerName: 'Action', type: 'dateTime', width: 250, renderCell: (cell) => {
                return <>

                    {
                        cell && cell.row.comment_stst ? (<IconButton color="secondary" onClick={e => { visibilityOffOn(cell.row.id) }}><VisibilityOutlined /></IconButton>) : (<IconButton onClick={e => { visibilityOffOn(cell.row.id) }} color="secondary"><VisibilityOffOutlined /></IconButton>)
                    }
                
                    <IconButton color="secondary" onClick={e => { EditComment(cell.row) }}><EditOutlined /></IconButton>
                

                </>
            }
        },
    ];
    const [products, setproducts] = useState()
    async function dataHandler() {
        const products = await ApiHelper.fetchProducts()
        setproducts(products.data)
    }

    async function fetchProductsReview() {
        setIsLoading(true)
        const result = await ApiHelper.fetchProductsReview()
        if (typeof result.status !== undefined && result.status === "success") {
            setIsLoading(false)
            setDataRows([...result.data])
        } else {
            setIsLoading(false)
        }
    }
    const AddReview = e => {
        dataHandler()
        setAddreviewModal(true)
    }
    useEffect(() => {
        fetchProductsReview()
    }, [])
    // console.log(EditReviewData)
    document.title = `Products Reviews`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2} display="flex" alignItems="center" className="justify-content-between">
                    <div>
                        <Typography variant="h5">
                            All Products Review
                        </Typography>
                        <Typography variant="subtitle1">
                            View all Products Review.
                        </Typography>
                    </div>
                    <Button variant="outlined" onClick={AddReview} color="secondary">
                        Add Review
                    </Button>
                </Box>
                <Divider />
                <Box m={4}>
                    <DataGrid loading={isLoading} autoHeight={true} rows={dataRows} getRowId={e => { return e.id }} columns={columns} pageSize={10} />
                </Box>
            </div>
            <FormBasedDialog
                status={{ state: setAddreviewModal, isOpen: AddreviewModal }}
                title={<><Typography variant="h6">Add Review</Typography><Typography variant="subtitle2"><b>Note</b>: Customer Name Generate as per the product suppliment for</Typography></>}
                component={<AddReviewForm functional={{ state: setAddreviewModal, onSuccess: fetchProductsReview }} products={products} />}
            />
            <FormBasedDialog
                status={{ state: setEditReviewModal, isOpen: EditReviewModal }}
                title={<><Typography variant="h6">Edit Review</Typography><Typography variant="subtitle2">Edit Comments</Typography></>}
                component={<EditReviews functional={{ state:  setEditReviewModal, onSuccess: fetchProductsReview, previasdata:EditReviewData,setEditData: setEditReviewData }}  />}
            />
        </div>
    )
}
