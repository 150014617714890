import { Box, Button, FormControl, FormGroup, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import publicIp from 'public-ip';
import React, { useEffect, useState, useRef } from 'react';
import ErrorBox from '../../functions/ErrorBox';
import { ApiHelper as ApiHelperC } from '../../helpers';
import PATH from '../../helpers/Routes';
import NavBar from '../../widget/NavBar';

export default function AddAgent() {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const [roleArry, setroleArry] = useState([])
    // const [newuserinfo, setnewuserinfo] = useState({
    //     name: "",
    //     email: "",
    //     phone: "",
    //     role: "",
    //     password: "",
    // })
    const userInforef = useRef({
        name: "rajat",
        email: "",
        phone: "",
        role: "",
        password: "",
    })

    const ApiHelper = new ApiHelperC()
    const getroles = async () => {
        const roles = await ApiHelper.getUserRoles();
        setroleArry(roles)
    }

    useEffect(() => {
        getroles()
        // eslint-disable-next-line
    }, [])
    const submitHandler = async (data) => {
        if (!data.email || data.email === "" || data.email.length < 10) {
            return setresponse_data({ ...response_data, status: "Validation Error", message: "Please enter valid Email id", OC: true })
        }
        for (let property in data) {
            if (data[property] === undefined || data[property] === "" || data[property].length <= 0) {
                return setresponse_data({ ...response_data, status: "Validation Error", message: "Required field is Empty", OC: true })
            }
        }
        let newuser = {
            ...data,
            ip_address: await publicIp.v4()
        }
        let respons = await ApiHelper.addUsers(newuser)
        if (respons.status === "success") {
            setresponse_data({ ...response_data, status: respons.status, message: `New ${respons.message}`, OC: true, redirect_to: PATH.users.viewUser })
        }

    }
    document.title = `Add New Agent`
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} my={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            Add Users
                        </Typography>
                        <Typography variant="subtitle1">
                            Add new users in the system.
                        </Typography>
                    </div>

                </Box>
                <hr />
                <div className="col-12 col-lg-6">
                    <Box m={4}>
                        <h2 className="mb-4">New User Details</h2>
                        <FormGroup>
                            <div className="row">
                                <div className="col-12 mb-5">
                                    <FormControl fullWidth variant="outlined">
                                        <Typography variant="subtitle1" className="text-muted fw-bold">Name</Typography>
                                        <TextField fullWidth type="text" name="name" id="name" variant="outlined" placeholder="Enter name"
                                            onChange={(e) => { userInforef.current.name = e.target.value }}
                                        ></TextField>
                                    </FormControl>
                                </div>
                                <div className="col-12 mb-5">
                                    <FormControl fullWidth variant="outlined">
                                        <Typography variant="subtitle1" className="text-muted fw-bold">Email</Typography>
                                        <TextField fullWidth type="text" name="email" id="email" variant="outlined" placeholder="Enter email"
                                            onChange={(e) => { userInforef.current.email = e.target.value }}
                                        ></TextField>
                                    </FormControl>
                                </div>
                                <div className="col-12 mb-5">
                                    <FormControl fullWidth variant="outlined">
                                        <Typography variant="subtitle1" className="text-muted fw-bold">Phone</Typography>
                                        <TextField fullWidth type="text" name="phone" id="phone" variant="outlined" placeholder="Enter phone"
                                            onChange={(e) => { userInforef.current.phone = e.target.value }}
                                        ></TextField>
                                    </FormControl>
                                </div>
                                <div className="col-12 mb-5">
                                    <FormControl fullWidth variant="outlined">
                                        <Typography variant="subtitle1" className="text-muted fw-bold">Select Role</Typography>
                                        <Select
                                            label="Select category"
                                            name="role"
                                            onChange={(e) => { userInforef.current.role = e.target.value }}
                                            // inputProps={{
                                            //     name: 'role',
                                            //     id: 'outlined-age-native-simple',
                                            // }}
                                        >
                                            <MenuItem disabled={true} aria-label="None" value="">
                                                Select-Your-Role
                                            </MenuItem>
                                            {roleArry ? (roleArry.map((role) => (
                                                <MenuItem key={role} value={role}>{role}</MenuItem>
                                            ))) : (<></>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-12 mb-5">
                                    <FormControl fullWidth variant="outlined">
                                        <Typography variant="subtitle1" className="text-muted fw-bold">Password</Typography>
                                        <TextField fullWidth type="password" name="password" id="password" variant="outlined" placeholder="Enter password"
                                            onChange={(e) => { userInforef.current.password = e.target.value }}
                                        ></TextField>
                                    </FormControl>
                                </div>
                                <div className="col-12 mb-5">
                                    <Button variant="outlined" color="primary" onClick={(e) => submitHandler(userInforef.current)}>
                                        Sign Up
                                    </Button>
                                </div>
                            </div>
                        </FormGroup>
                    </Box>
                </div>
            </div>
        </div>
    )
}
