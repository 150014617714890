import React from 'react';
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ViewModuleRoundedIcon from '@material-ui/icons/ViewModuleRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { ApiHelper as ApiHelperc, AuthHelper as Authc } from '../helpers';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PATH from '../helpers/Routes';
import { useHistory } from 'react-router';
import { AccountCircle, AllInbox, Category, Dashboard, ExpandLess, ExpandMore, Info, Pages, People, PersonAdd, PostAdd, Share, TrendingUp } from '@material-ui/icons';
import { Collapse, Menu, MenuItem} from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function ResponsiveDrawer(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const Auth = new Authc()
    const ApiHelper = new ApiHelperc()
    const history = useHistory()
    const [userData, setuserData] = useState({})
    const pageSelected = history.location.pathname
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [ordercollapsible, setordercollapsible] = useState(false);
    const [collapsible, setcollapsible] = useState(false)
    async function fetchUserInformation() {
        const result = await ApiHelper.fetchUserInfo("full_name")
        setuserData({ ...result })
        return
    }



    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        Auth.logout((status) => {
            if (!status) history.push(PATH.login)
        })
    }
    const handleViewProfile = () => {
        /**
         * Profile View Of User
         */
    }
    const container = window !== undefined ? () => window().document.body : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };
    const MenuItems = [
        {
            name: "Profile",
            onclick: handleViewProfile
        },
        {
            name: "Logout",
            onclick: handleLogout
        },
    ]
    useEffect(() => {
        fetchUserInformation()
        // eslint-disable-next-line
        return () => {
            setuserData({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const drawer = (
        <div>
            <div className={`${classes.toolbar} small-d-navbar`} >
                AR AYURVEDA PVT.LTD
            </div>
            {/* <Divider/> */}
            <div className={classes.drawerContainer}>
                <List>
                    <Link to={PATH.dashboard} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.dashboard}>
                            <ListItemIcon><Dashboard /></ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.inquiry} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.inquiry}>
                            <ListItemIcon><ImportContactsIcon /></ListItemIcon>
                            <ListItemText primary={"Inquiry"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.product.view} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.product.view}>
                            <ListItemIcon><AllInbox /></ListItemIcon>
                            <ListItemText primary={"View Product"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.product.add} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.product.add}>
                            <ListItemIcon><PostAdd /></ListItemIcon>
                            <ListItemText primary={"Add Product"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.users.addUser} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.users.addUser}>
                            <ListItemIcon><PersonAdd /></ListItemIcon>
                            <ListItemText primary={"Add users"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.users.viewUser} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.users.viewUser}>
                            <ListItemIcon><People /></ListItemIcon>
                            <ListItemText primary={"View users"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.product.productmedia} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.product.productmedia}>
                            <ListItemIcon><AllInbox /></ListItemIcon>
                            <ListItemText primary={"Product Media"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.product.trending} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.product.trending}>
                            <ListItemIcon><TrendingUp /></ListItemIcon>
                            <ListItemText primary={"Trending Product"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.post.add} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.post.add}>
                            <ListItemIcon><PostAdd /></ListItemIcon>
                            <ListItemText primary={"Add post"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.post.view} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.post.view}>
                            <ListItemIcon><AllInbox /></ListItemIcon>
                            <ListItemText primary={"View post"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.category} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.category}>
                            <ListItemIcon><Category /></ListItemIcon>
                            <ListItemText primary={"Category"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.product.review} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.product.review}>
                            <ListItemIcon><PostAdd /></ListItemIcon>
                            <ListItemText primary={"Product Review"} />
                        </ListItem>
                    </Link>
                </List>
                <List>
                    <Link to={PATH.order.view} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.order.view}>
                            <ListItemIcon><ViewModuleRoundedIcon /></ListItemIcon>
                            <ListItemText primary={"View Order"} />
                        </ListItem>
                    </Link>
                    <List>
                        <ListItem button onClick={e => { setordercollapsible(oldstate => !oldstate) }}>
                            <ListItemIcon><PostAdd /></ListItemIcon>
                            <ListItemText primary={"Order Room"} />
                            {ordercollapsible ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </List>
                    <Collapse
                        in={ordercollapsible}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component='li' disablePadding >
                            <Link to={PATH.order.add} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.add}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Add Order"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.codsuccess} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.codsuccess}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Cod Success"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.pripaidsuccess} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.pripaidsuccess}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Pripaid Success"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.dispatch} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.dispatch}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Dispatch"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.verify} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.verify}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Verify"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.rejected} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.rejected}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Rejected"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.working} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.working}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Working"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.pending} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.pending}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Pending"} />
                                </ListItem>
                            </Link>
                            <Link to={PATH.order.cancel} style={{ color: "#020202e8", textDecoration: "none" }}>
                                <ListItem button selected={pageSelected === PATH.order.cancel}>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Cancel"} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>

                </List>
                <ListItem button onClick={e => { setcollapsible(oldstate => !oldstate) }}>
                    <ListItemIcon><Pages /></ListItemIcon>
                    <ListItemText primary={"Pages"} />
                    {collapsible ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={collapsible}
                    timeout='auto'
                    unmountOnExit
                >
                    <List component='li' disablePadding >
                        <Link to={PATH.appereance} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.appereance}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Appearance" />
                            </ListItem>
                        </Link>
                        <Link to={PATH.about} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.about}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="About" />
                            </ListItem>
                        </Link>
                        <Link to={PATH.privacy_policy} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.privacy_policy}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Privacy Policy" />
                            </ListItem>
                        </Link>
                        <Link to={PATH.shipping_policy} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.shipping_policy}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Shipping Policy" />
                            </ListItem>
                        </Link>
                        <Link to={PATH.terms_condition} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.terms_condition}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Terms and Condition" />
                            </ListItem>
                        </Link>
                        <Link to={PATH.return_refund} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.return_refund}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Return & Refund Policy" />
                            </ListItem>
                        </Link>
                        <Link to={PATH.faqs} style={{ color: "#020202e8", textDecoration: "none" }}>
                            <ListItem button selected={pageSelected === PATH.faqs}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="FAQs" />
                            </ListItem>
                        </Link>
                    </List>
                </Collapse>
                    <List>
                <Link to={PATH.share} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.share}>
                            <ListItemIcon><Share /></ListItemIcon>
                            <ListItemText primary={"Social"} />
                        </ListItem>
                </Link>
                    </List>
                    <List>
                <Link to={PATH.setting} style={{ color: "#020202e8", textDecoration: "none" }}>
                        <ListItem button selected={pageSelected === PATH.setting}>
                            <ListItemIcon><Info /></ListItemIcon>
                            <ListItemText primary={"Information"} />
                        </ListItem>
                </Link>
                    </List>
            </div>
        </div>
    );
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={"justify-content-between"}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        AR AYURVEDA PVT.LTD
                    </Typography>
                    {Auth.token && (
                        <div data-token={Auth.token}>
                            <Button
                                variant="outlined"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                startIcon={<AccountCircle />}
                            >
                                {userData.full_name && userData.full_name}
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                {
                                    MenuItems.map((value) => (
                                        <MenuItem key={value.name} onClick={value.onclick}>{value.name}</ MenuItem>
                                    ))
                                }
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            {/* <main className={classes.content}> */}
            <div />
            {/* <Typography paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
                    facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
                    gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
                    donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                    adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
                    Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
                    imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
                    arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                    donec massa sapien faucibus et molestie ac.
                </Typography>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla
                    facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac
                    tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat
                    consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed
                    vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In
                    hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et
                    tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique sollicitudin
                    nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra maecenas
                    accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography> */}
            {/* </main> */}
        </div>
    );
}


export default ResponsiveDrawer;
