class Auth {
    constructor() {
        this.token = localStorage.getItem('token')
        this.authenticated = (this.token === "" || this.token === null) ? false : this.token;
    }
    async login(token) {
        localStorage.setItem('token', token);
        this.authenticated = true
        return localStorage.getItem('token')
    }

    logout(cb) {
        localStorage.clear()
        this.authenticated = false;
        cb(this.authenticated)
    }

    isAuthenticated() {
        return this.authenticated
    }

    getInfo(key) {
        let information = localStorage.getItem('information');
        information = JSON.parse(information)
        if (typeof information[key] !== undefined) {
            return information[key]
        }
        return ""
    }
}


export default Auth