import { DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Button, Dialog } from '@material-ui/core';
import React, { forwardRef} from 'react'
import { useHistory } from 'react-router';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ErrorBox(props) {
    const history = useHistory()
    const { response_data, setresponse_data } = props;
    const handleClose = () => {
        setresponse_data({ ...response_data, OC: false })
        if (typeof response_data.redirect_to !== "undefined" && response_data.redirect_to.length > 0 && response_data.redirect_to !== "" && response_data.redirect_to !== "" && response_data.redirect_to !== null && response_data.redirect_to !== "undefined") {
            history.push(response_data.redirect_to)
        }

    }

    return (
        <div>
            <Dialog
                open={response_data.OC}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{response_data.status}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {response_data.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
