import { Button, Card, CardContent, FormControl, FormGroup, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Alert from '@material-ui/lab/Alert';
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { ApiHelper as ApiHelperC, AuthHelper as Authc, Routes as PATH } from '../../helpers'

export default function LoginComponent(props) {
    const history = useHistory()
    const ApiHelper = new ApiHelperC()
    const [errorState, setErrorState] = useState('')
    const Auth = useMemo(() => new Authc(), [])
    useEffect(() => {
        if (Auth.isAuthenticated()) {
            history.push(PATH.dashboard);
        }
    }, [Auth, history])

    const [loginDetails, setLoginDetail] = useState({
        isLoading: false,
        email: "",
        password: ""
    })

    const handleEmailChange = (e) => {
        setLoginDetail(oldDetails => ({
            ...oldDetails, email: e.target.value,
        }))
    }

    const handlePasswordChange = (e) => {
        setLoginDetail(oldDetails => ({
            ...oldDetails, password: e.target.value,
        }))
    }

    const authenticateUser = async () => {
        setLoginDetail(oldDetails => ({
            ...oldDetails, isLoading: true,
        }))
        if (loginDetails.email.trim() !== '' && loginDetails.password.trim() !== '') {
            var response = await ApiHelper.authenticateUser(loginDetails.email.trim(), loginDetails.password.trim())
            if (typeof response.data !== 'undefined' && Array.isArray(response.data) && response.data.length > 0) {
                var data = response.data[0]
                var authResult = await Auth.login(data.token)
                if (authResult === data.token) {
                    history.push(PATH.dashboard)
                } else {
                    setErrorState('Invalid email or password')
                    //console.log('Auth result invalid')
                }
            } else {
                setLoginDetail(oldDetails => ({
                    ...oldDetails, isLoading: false,
                }))
                setErrorState(response.message ?? 'Something went wrong!')
                //console.log('Data not found!')
            }
        } else {
            setLoginDetail(oldDetails => ({
                ...oldDetails, isLoading: false,
            }))
            setErrorState('Email or password missing!')
            //console.log('Email or password missing from fields!')
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-dark">
            <div>
                {
                    errorState !== '' ? <Alert severity="error" color="error">
                        {errorState}
                    </Alert> : null
                }
                <Card style={{ minWidth: 400 }} className="mt-2">
                    <CardContent className="p-0">
                        <div style={{ padding: '20px' }} className="text-center">
                            <img src="https://www.arayurveda.com/uploads/ar-gif3.gif" className="img-fluid" alt="Ayurveda Life Care Logo" />
                        </div>
                        <FormGroup className="m-4">
                            <FormControl>
                                <TextField id="username" label="Email" value={loginDetails.email} onChange={handleEmailChange} />
                                <TextField className="mt-2" id="password" type="password" label="Password" value={loginDetails.password} onChange={handlePasswordChange} />
                                <Button onClick={authenticateUser} disabled={loginDetails.isLoading} variant="contained" color="primary" className="mt-4">
                                    Login
                                </Button>
                            </FormControl>
                        </FormGroup>
                    </CardContent>
                </Card>
            </div>
        </div >
    )
}
