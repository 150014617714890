import { AuthHelper as Auth } from "."
import Axios from "axios"
const BASEURL = process.env.REACT_APP_BASE_URL

function convertToformData(data) {
    const formdata = new FormData()
    for (var key in data) {
        formdata.append(key, data[key])
    }
    return formdata
}

class ApiHelper {

    constructor() {
        const AuthO = new Auth()
        this.token = AuthO.token !== "" ? AuthO.token : ""
    }
    async authenticateUser(email, password) {
        var formData = new FormData()
        formData.append('username', email)
        formData.append('password', password)
        var requestOptions = {
            'method': 'POST',
            'body': formData,
        }
        try {
            var response = await fetch(`${BASEURL}login`, requestOptions)
            var json = await response.json()
            if (response.ok) {
                return json
            } else if (response.status === 401) {
                return {
                    message: 'Invalid email or password!'
                }
            } else {
                return {
                    message: 'Something went wrong!'
                }
            }
        } catch (error) {
            return {
                message: 'Something went wrong!'
            }
        }
    }

    async fetchUserInfo(field) {
        var formData = new FormData()
        formData.append('keys', field)
        var requestOptions = {
            'method': 'POST',
            'body': formData,
        }
        var response = await fetch(`${BASEURL}get/user?token=${this.token}`, requestOptions)
        var json = await response.json()
        if (response.ok) {
            return json.data[0]
        } else if (response.status === 401) {
            return []
        } else if (response.status === 406) {
            return []
        }
    }
    async SaveEditAppereance(data, id) {
        var requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var response = await fetch(`${BASEURL}update/appereance/info/${id}?token=${this.token}`, requestOptions)
        var json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 401) {
            return []
        } else if (response.status === 406) {
            return []
        }
    }
    async SaveEditfaq(data, id) {
        var requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var response = await fetch(`${BASEURL}update/faq/${id}?token=${this.token}`, requestOptions)
        var json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 401) {
            return []
        } else if (response.status === 406) {
            return []
        }
    }

    async addProductReview(data) {
        var requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        var response = await fetch(`${BASEURL}add/anonymous/review?token=${this.token}`, requestOptions)
        var json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 401) {
            return {
                staus: "fail"
            }
        } else if (response.status === 406) {
            return {
                staus: "fail"
            }
        }
    }



    async createCategory(data) {
        const body = convertToformData(data ? data : {})
        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}category/add?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            let i = Math.random()
            json.data = json.data.map(value => { value['id'] = ++i; return value })
            return json
        }
    }


    async createOption(data) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}create/option?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        }
    }
    async createSlider(data) {
        const body = convertToformData(data ? data : {})
        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}create/slider?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        }
    }
    async AddTrending(data) {
        const body = convertToformData({ proid: data })
        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}add/to/trending?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return json
        }
    }

    async RemoveTrending(id) {
        const response = await fetch(`${BASEURL}products/trending/${id}?token=${this.token}`, {
            method: 'DELETE'
        })
        let json = await response.json()
        if (response.ok) {
            return {
                success: true,
                data: json,
            }
        } else {
            return {
                success: false,
                data: json,
                message: json.message ?? 'Something went wrong!'
            }
        }
    }
    async RemoveFaq(id) {
        const response = await fetch(`${BASEURL}faq/delete/${id}?token=${this.token}`, {
            method: 'DELETE'
        })
        let json = await response.json()
        if (response.ok) {
            return {
                success: true,
                data: json,
            }
        } else {
            return {
                success: false,
                data: json,
                message: json.message ?? 'Something went wrong!'
            }
        }
    }

    async fetchAppereance() {
        const response = await fetch(`${BASEURL}appereance/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }

    async GetInquiry() {
        const response = await fetch(`${BASEURL}fetch/all/inquiry/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }

    async getstate(pincod) {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        let json = await Axios.get(`https://new.arayurveda.com/api/fetchFromPincode/${pincod}`, {
            cancelToken: source.token
        }).catch(function (thrown) {
            if (Axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);
            } else {

            }
        });
        source.cancel('Operation canceled by the user.'); // eslint-disable-next-line
        return json
        // cancel the request (the message parameter is optional)

    }

    async fetchOption() {
        const response = await fetch(`${BASEURL}fetch/setting/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchOrderDetails(paymentype = "") {
        let qs = ""
        if (paymentype !== "") {
            qs = `&payment_type=${paymentype}`
        }
        const response = await fetch(`${BASEURL}fetch/orders/?token=${this.token}${qs}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }

    async EditPostGet(post_id) {
        const response = await fetch(`${BASEURL}/post/${post_id}?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }


    async fetchPostCategory() {
        const response = await fetch(`${BASEURL}category/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }

    async fetchEditPorduct(proid) {
        const response = await fetch(`${BASEURL}products/${proid}/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }

    async UpdatePost(postid, data) {
        const requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}update/post/info/${postid}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            // console.log(json)
            return json
        } else {
            return ""
        }
    }

    async UpdateReviews(reviewid, data) {
        const requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}update/review/${reviewid}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            // console.log(json)
            return json
        } else {
            return ""
        }
    }

    async apperiensindexUD(data) {
        const requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}/update/tindex/appereance?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            // console.log(json)
            return json
        } else {
            return ""
        }
    }

    async deleteAppereance(id) {
        const requestOptions = {
            'method': 'DELETE',
        }
        const response = await fetch(`${BASEURL}delete/appreance/${id}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async UpdatePostVisibility(postid) {
        const requestOptions = {
            'method': 'PUT',
        }
        const response = await fetch(`${BASEURL}update/post/visibility/${postid}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            // console.log(json)
            return json
        } else {
            return ""
        }
    }

    async UpdateProduct(proid, data) {
        const requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}update/product/info/${proid}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            // console.log(json)
            return json
        } else {
            return ""
        }
    }

    async uploadImage(data, key = "", multiple = false) {
        let formdata = new FormData()
        for (let keyy in data) {
            formdata.append("image", data[keyy])
        }
        const body = formdata

        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}upload/image/?token=${this.token}&key=${key}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async createProduct(data) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}product/add/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }
    async createPost(data) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}post/add/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async fetchPost() {
        const response = await fetch(`${BASEURL}post/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchProducts() {
        const response = await fetch(`${BASEURL}products/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchMediaProducts() {
        const response = await fetch(`${BASEURL}products/media/gallary?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchTrendingProducts() {
        const response = await fetch(`${BASEURL}products/trending?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async updateTredingProduct(data) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}update/trending/product?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchProductsReview() {
        const response = await fetch(`${BASEURL}products/reviews/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchSocialUrl() {
        const response = await fetch(`${BASEURL}fetch/social/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }
    async fetchFaq() {
        const response = await fetch(`${BASEURL}fetch/faq/?token=${this.token}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else if (response.status === 404) {
            return json
        }
    }

    async createSocial(data) {
        const body = convertToformData(data ? data : {})
        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}create/social?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        }
    }
    async createFaq(data) {
        const body = convertToformData(data ? data : {})
        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}create/faq?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        }
    }

    async createAbout(data) {
        const body = convertToformData(data ? data : {})
        const requestOptions = {
            'method': 'POST',
            'body': body,
        }
        const response = await fetch(`${BASEURL}create/option/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async fetchInformation(key) {
        var formData = new FormData()
        formData.append('key', key)
        var requestOptions = {
            'method': 'POST',
            'body': formData,
        }
        var response = await fetch(`${BASEURL}get/option?token=${this.token}`, requestOptions)
        var json = await response.json()
        if (response.ok) {
            return json.data[0]
        } else if (response.status === 401) {
            return []
        } else if (response.status === 406) {
            return []
        }
    }

    async fetchUploadImgProductMedia(image, id) {
        var formData = new FormData()
        formData.append('image', image)
        formData.append('id', id)
        var requestOptions = {
            'method': 'POST',
            'body': formData,
        }
        var response = await fetch(`${BASEURL}upload/image?token=${this.token}`, requestOptions)
        var json = await response.json()
        if (response.ok) {
            return json.data[0]
        } else if (response.status === 401) {
            return []
        } else if (response.status === 406) {
            return []
        }
    }

    async createSetting(data) {
        const formData = new FormData()
        formData.append("data", JSON.stringify(data))
        const requestOptions = {
            'method': 'POST',
            'body': formData,
        }
        const response = await fetch(`${BASEURL}create/information/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }
    async addProductMedia(data, proid) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}add/product/media/${proid}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async deleteProductMedia(id) {
        const requestOptions = {
            'method': 'DELETE',
        }
        const response = await fetch(`${BASEURL}delete/product/media/${id}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async downloadProductMedia(id) {
        const response = await fetch(`${BASEURL}download/product/media/${id}?token=${this.token}`)
        return response.url
    }

    async getUser(role = '') {
        const response = await fetch(`${BASEURL}fetch/users?token=${this.token}&role=${role}`)
        let json = await response.json()
        if (response.ok && typeof json.data !== 'undefined' && Array.isArray(json.data)) {
            return {
                success: true,
                message: 'Success',
                data: json.data
            }
        } else {
            return {
                success: true,
                message: json.message ?? 'Something went wrong!',
                data: null,
            }
        }
    }

    async getrejected(status) {
        const response = await fetch(`${BASEURL}fetch/order/of/${status}?token=${this.token}`)
        let json = await response.json()
        // console.log(json.data)
        return json.data
    }
    async getUserRoles() {
        const response = await fetch(`${BASEURL}users/role?token=${this.token}`)
        let json = await response.json()
        // console.log(json.data)
        return json.data
    }

    async addUsers(data) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}create/user?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            // console.log(json)
            return json
        } else {
            return ""
        }
    }


    async crmStatus(data, id) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}setup/orderroom/${id}/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async getOrder(id) {
        const response = await fetch(`${BASEURL}fetch/order/${id}/?token=${this.token}`)
        let json = await response.json()
        // console.log(json.data)
        return json.data
    }

    async createOrders(data) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}create/order/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }
    async updateOrders(data, orderid) {
        const requestOptions = {
            'method': 'PUT',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}update/order/info/${orderid}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }
    async updateReview(id) {
        const requestOptions = {
            'method': 'PUT',
        }
        const response = await fetch(`${BASEURL}update/product/review/${id}?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }


    async EditProductmedia(data, id) {
        const requestOptions = {
            'method': 'POST',
            'body': JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const response = await fetch(`${BASEURL}update/product/media/${id}/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

    async deleteorder(id) {
        const requestOptions = {
            'method': 'DELETE',
        }
        const response = await fetch(`${BASEURL}delete/order/${id}/?token=${this.token}`, requestOptions)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }


    async Marktopriamryproductmedia(id, proid) {
        const response = await fetch(`${BASEURL}update/product/mark/media/${id}/?token=${this.token}&proid=${proid}`)
        let json = await response.json()
        if (response.ok) {
            return json
        } else {
            return ""
        }
    }

}

export default ApiHelper