const PATH = {
    login: "/",
    product: {
        add: "/product/add",
        view: "/product/view",
        trending: "/product/trending",
        review: "/product/review",
        edit: "/product/edit/:id",
        productmedia: "/product/productmedia"
    },
    inquiry:"/inquiry",
    users: {
        addUser: "/users/add",
        viewUser: "/users/view"
    },
    category: "/category",
    post: {
        add: "/post/add",
        view: "/post",
        edit: "/post/:id"
    },
    services: {
        add: "/service/add",
        view: "/service",
    },
    dashboard: "/dashboard",
    about: "/about",
    privacy_policy: "/privacy-policy",
    shipping_policy: "/shipping-policy",
    terms_condition: "/terms-condition",
    return_refund: "/return-refund",
    faqs: "/faqs",
    appereance: "/appereance",
    setting: "/setting",
    order: {
        view: "/order",
        add: "/order/add",
        dashboard: "/order/dashboard",
        codsuccess: "/order/codsuccess",
        pripaidsuccess: "/order/pripaidsuccess",
        dispatch: "/order/dispatch",
        verify: "/order/verify",
        rejected: "/order/rejected",
        working: "/order/working",
        edit: "/order/edit/:id",
        pending: "/order/pending",
        cancel: "/order/cancel"
    },
    share: "/social",
    carrer: {
        add: "/carrer/add",
        view: "/carrer",
    },
    portfolio: {
        add: "/portfolio/add",
        view: "/portfolio",
    }
}
export default PATH