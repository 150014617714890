import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useState, useEffect } from 'react'
import { ApiHelper } from '../../helpers'
import NavBar from '../../widget/NavBar'
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment'

export default function ViewAgent() {

    const [viewUserState, setViewUserState] = useState({
        'isLoading': true,
        'data': [],
    })

    const getdata = async () => {
        const res = await new ApiHelper().getUser();
        if (res.data !== null) {
            setViewUserState(old => ({ ...old, isLoading: false, data: res.data }))
            // console.log(res.data)
        } else {
            setViewUserState(old => ({ ...old, isLoading: false }))
            // console.log(res.message)
        }
    }

    useEffect(() => {
        getdata()
    }, [])

    const columns = [
        {
            field: 'userid',
            headerName: 'User ID',
            width: 150
        },
        {
            field: 'full_name',
            headerName: 'Full Name',
            width: 200,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 140,
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 140,
        },
        {
            field: 'user_name',
            headerName: 'Username',
            width: 140,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 150,
            renderCell: (cell) => {
                return moment(cell.row.created_at).format('YYYY-MM-DD')
            }
        },
    ];
    document.title = `View All Users`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2}>
                    <Typography variant="h5">
                        View Users
                    </Typography>
                    <Typography variant="subtitle1">
                        View system users.
                    </Typography>
                </Box>
                <Divider />
                <Box m={4}>
                    <DataGrid autoHeight={true} rows={viewUserState.data} getRowId={e => e.userid} columns={columns} loading={viewUserState.isLoading} pageSize={10} />
                </Box>
            </div>
        </div>
    )
}
