import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { ApiHelper as ApiHelperC } from "../helpers";
import ErrorBox from "./ErrorBox";

const ApiHelper = new ApiHelperC();

const AddReviewForm = (props) => {
  const [response_data, setresponse_data] = useState({
    status: "",
    message: "",
    OC: false,
    redirect_to: "",
  });
  const { products, functional } = props;
  const [ReviewData, setReviewData] = useState({
    product: "",
    rating: "",
    review: "",
    created_at: "2021-10-21",
  });
  const setValue = (key, e) => {
    let data = {};
    data[key] = e.target.value;
    setReviewData({ ...ReviewData, ...data });
  };
  const SubmitReviewAdd = async () => {
    const response = await ApiHelper.addProductReview(ReviewData);
    if (
      response.status &&
      typeof response.status !== undefined &&
      response.status === "success"
    ) {
      setresponse_data({
        ...response_data,
        status: response.status,
        message: `New review uploaded`,
        OC: true,
      });
      setReviewData({ product: "", rating: "", review: "",created_at:"2021-10-21"});
      if (
        typeof functional !== "undefined" &&
        typeof functional === "object" &&
        Object.keys(functional).length > 0
      ) {
        functional.onSuccess();
        functional.state(false);
      }
    } else {
      // ON FAil
    }
  };
  return (
    <>
      <FormGroup>
        <ErrorBox
          setresponse_data={setresponse_data}
          response_data={response_data}
        ></ErrorBox>
        <FormControl fullWidth variant="outlined">
          <Select
            value={ReviewData.product}
            name="product"
            fullWidth
            onChange={(e) => {
              setValue("product", e);
            }}
            displayEmpty
            inputProps={{
              name: "product",
              id: "outlined-age-native-simple",
            }}
            id="product"
          >
            <MenuItem disabled={true} value="">
              <em>--Select-Product--</em>
            </MenuItem>
            {products &&
              products.map((product) => (
                <MenuItem
                  onClick={(e) => {}}
                  key={product.id}
                  value={product.proid}
                >
                  {product.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <Select
            className="mt-3"
            value={ReviewData.rating}
            name="rating"
            onChange={(e) => {
              setValue("rating", e);
            }}
            displayEmpty
            inputProps={{
              name: "rating",
              id: "outlined-age-native-simple",
            }}
            id="rating"
          >
            <MenuItem disabled={true} value="">
              <em>--Select-Rating--</em>
            </MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </Select>
        </FormControl>
        <Divider />
        <FormControl fullWidth variant="outlined" className="mt-3">
          <TextField
            type="text"
            name="review"
            fullWidth
            multiline
            rows={4}
            rowsMax={5}
            value={ReviewData.review}
            id="review"
            variant="outlined"
            placeholder="Enter Review"
            onChange={(e) => {
              setValue("review", e);
            }}
          ></TextField>
        </FormControl>
        <FormControl fullWidth variant="outlined" className="mt-3">
          <Typography variant="subtitle1" className="text-muted fw-bold">
            Review Date
          </Typography>
          <TextField
            id="Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={ReviewData.created_at ? ReviewData.created_at : "2021-10-21"}
            value={ReviewData.created_at}
            onChange={(e) => setReviewData(oldstate => {return{...oldstate,created_at:e.target.value}}) }
          />

        </FormControl>
      </FormGroup>
      <Box display="flex" mt={3} justifyContent="end">
        <Button onClick={SubmitReviewAdd} color="primary">
          Save
        </Button>
      </Box>
    </>
  );
};

export default AddReviewForm;
