import { Box, Typography, Button, TextField, makeStyles, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import NavBar from '../../widget/NavBar'
import { ApiHelper as ApiHelperC } from '../../helpers';
import ErrorBox from '../../functions/ErrorBox';

function ConfirmationDialogRaw(props) {
  const [response_data, setresponse_data] = useState({
    status: "",
    message: "",
    OC: false,
    redirect_to: "",
})
  const { onClose, ApiHelper, value: valueProp, cb, isLoading, featureImageUri, ImageURI, defaultRow, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [categoryInfo] = useState({
    title: "",
    slug: "",
    _desc: "",
    alt: "",
    _title: "",
    image: ""
  })
  const handleChangeCategory = (key, value) => {
    categoryInfo[key] = value
  }
  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
    return () => {
      setValue('Dione');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp, open]);


  const handleCancel = () => {
    onClose(value);
  };

  const handleOk = async () => {
    const create = { ...categoryInfo }
    for(let fild in create){
      if(create[fild] === "" || create[fild] === "undefined"){
        return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field ${fild} is empty`, OC: true })
      }
    }
    const response = await ApiHelper.createCategory(create)
    if (response.status === "success") {
      onClose(value)
      isLoading(false)
      cb([...defaultRow, ...response.data])
      setresponse_data({ ...response_data, status: response.status, message: `New category successfully added`, OC: true })

    }
  };
  const HadleChangePostInput = (key, value) => {
    categoryInfo[key] = value
  }
  const fileUpload = async (image) => {
    let result = await ApiHelper.uploadImage(image, "category")
    if(result){
      if(result.status === "success"){
        setresponse_data({ ...response_data, status: result.status, message: `Image successfully uploaded`, OC: true })
      }
    }
    if (result !== "") {
      result = result.data[0]
      featureImageUri(result.uri)
      HadleChangePostInput('image', result.path)
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
      <DialogTitle id="confirmation-dialog-title">Add Category</DialogTitle>
      <DialogContent dividers>
        <form noValidate autoComplete="off">
          <div>
            <div style={{ backgroundImage: `url(${ImageURI ?? ImageURI})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100%", height: 200, borderWidth: "1px", borderStyle: "dashed" }}>
            </div>
            <Button
              style={{ width: '100%' }}
              variant="contained"
              component="label"
              color="primary"
            >
              {"Add feature image"}
              <input
                onChange={e => { fileUpload(e.target.files) }}
                type="file"
                hidden
              />
            </Button>
          </div>
          <TextField
            id="outlined-full-width"
            placeholder="Category"
            helperText="Enter Category Name"
            fullWidth
            onChange={e => { handleChangeCategory('title', e.target.value) }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            placeholder="Slug"
            helperText="Enter permalink"
            fullWidth
            margin="normal"
            onChange={e => { handleChangeCategory('slug', e.target.value) }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            placeholder="Description"
            helperText="Enter category description"
            fullWidth
            multiline
            rowsMax={5}
            rows={5}
            margin="normal"
            onChange={e => { handleChangeCategory('_desc', e.target.value) }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            placeholder="Alternate name"
            helperText="Enter Alternate Name"
            fullWidth
            margin="normal"
            onChange={e => { handleChangeCategory('alt', e.target.value) }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            placeholder="Image Title Name"
            helperText="Enter Image Title Name"
            fullWidth
            margin="normal"
            onChange={e => { handleChangeCategory('_title', e.target.value) }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

export default function Category() {
  const classes = useStyles()
  const ApiHelper = new ApiHelperC()
  const [isLoading, setIsLoading] = useState(true)
  const [featureImageUri, setfeatureImageUri] = useState("")
  const [dataRows, setDataRows] = useState([])
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('Dione');

  const columns = [
    { field: 'image', renderCell: (image) => (<img alt={image.value} src={image.value} className="img-fluid" height={100} width={100} />), headerName: 'Image', width: 200, flex: 0 },
    { field: 'title', headerName: 'Category', width: 150 },
    { field: '_desc', headerName: 'Description', width: 150,},
    { field: 'slug', headerName: 'Slug', width: 150 },
    { field: 'created_at', headerName: 'Created At', type: 'dateTime', width: 250, },
  ];

  const handleClose = (newValue) => {
    setOpen(false);
    if (newValue) {
      setValue(newValue);
    }
  };
  async function fetchCategory() {
    const result = await ApiHelper.fetchPostCategory()
    let rows = []
    if (result.status === "success") {
      rows = [...result.data]
    }
    // console.log(rows)
    setDataRows([...rows])
    setIsLoading(false)
    return
  }
  useEffect(() => {
    fetchCategory()
    return () => {
      setDataRows([]);
      setIsLoading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  document.title = `Categorys`
  return (
    <div>
      <NavBar />
      <div className="text-start small-d">
        <Box mx={4} my={2} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <Typography variant="h5">
              All Category
            </Typography>
            <Typography variant="subtitle1">
              View all category.
            </Typography>
          </div>
          <Button variant="outlined" color="secondary" onClick={e => { setOpen(true) }}> Add Category </Button>
        </Box>
        <hr />
        <Box m={4} style={{ color: '#000', backgroundColor: '#fff' }}>
          <DataGrid loading={isLoading} autoHeight={true} rows={dataRows} getRowId={e => e.catid} columns={columns} pageSize={10} />
        </Box>
      </div>
      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id="add-category"
        keepMounted
        open={open}
        isLoading={setIsLoading}
        featureImageUri={setfeatureImageUri}
        ImageURI={featureImageUri}
        onClose={handleClose}
        value={value}
        ApiHelper={ApiHelper}
        defaultRow={dataRows}
        cb={setDataRows}
      />
    </div>
  )
}
