import { Box, Button, Divider, FormControl, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import { ApiHelper as ApiHelperc } from "../helpers";
import ErrorBox from "./ErrorBox";

export default function AppereanceForm(props) {
    const { functional } = props
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const [appereancData, setAppereanceData] = useState({
        title: "",
        image: "",
        slider_type: "",
        redirect_type: "",
        redirect: "",
        device_type: "desktop",
        link_to_banner:""
    })
    const ApiHelper = new ApiHelperc()
    const [featureImage, setFeatureImage] = useState({})

    const fileUpload = async (image) => {
        let result = await ApiHelper.uploadImage(image, "appereance")
        if (result.status === "success") {
            setresponse_data({ ...response_data, status: result.status, message: `Image upload successfull`, OC: true })
        }
        if (result !== "") {
            result = result.data
            if (result.length > 0) {
                setAppereanceData({ ...appereancData, image: result[0].path })
                setFeatureImage({ ...result[0] })
            } else {
                alert("Server Refuse to upload file")
            }
        }
    }
    const SaveAppereance = async (e) => {
        if(appereancData.title === "" || appereancData.title === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field title is empty`, OC: true })
        }
        if(appereancData.image === "" || appereancData.image === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field image is empty`, OC: true })
        }
        if(appereancData.slider_type === "" || appereancData.slider_type === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field slider_type is empty`, OC: true })
        }
        if(appereancData.redirect_type === "" || appereancData.redirect_type === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field redirect_type is empty`, OC: true })
        }
        if(appereancData.redirect === "" || appereancData.redirect === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field redirect is empty`, OC: true })
        }
        if(appereancData.device_type === "" || appereancData.device_type === "undefined"){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field device_type is empty`, OC: true })
        }
        if(appereancData.slider_type === "banner" && appereancData.device_type === "mobile" && appereancData.link_to_banner === ""){
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Required Field link_to_banner is empty`, OC: true })
        }
        let result = await ApiHelper.createSlider(appereancData)
        if (result) {
            if (result.status === "success" && result.status !== undefined && result.status !== 'undefined') {
                setAppereanceData({
                    title: "",
                    image: "",
                    slider_type: "",
                    redirect_type: "",
                    redirect: "",
                    device_type: "",
                    link_to_banner: ""

                })
                setFeatureImage({})
                functional.state(false)
                functional.onSuccess()
                setresponse_data({ ...response_data, status: result.status, message: `New ${appereancData.slider_type} uploaded in appereance table`, OC: true })
            }
        }
    }
    return (
        <div>
            <div className="row">
                <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
                <div className="col-md-12">
                    <Box m={4}>
                        <div className="mb-4 text-left">
                            <div style={{ backgroundImage: `url(${featureImage.uri ?? ""})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100%", height: 200, borderWidth: "1px", borderStyle: "double" }}>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <Button
                                        style={{ width: '100%' }}
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                    >
                                        {"Upload Gllary"}
                                        <input
                                            onChange={e => { fileUpload(e.target.files) }}
                                            type="file"
                                            hidden
                                            multiple
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <TextField id="appereancetitle" value={appereancData.title} onFocus={e => { }} onChange={(e) => { setAppereanceData({ ...appereancData, title: e.target.value }) }} variant="outlined" placeholder="Appereacne Title" />
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <Select
                                native
                                value={appereancData.slider_type}
                                onChange={e => { setAppereanceData({ ...appereancData, slider_type: e.target.value }) }}
                                label="Select Slider Type"
                                inputProps={{
                                    name: 'slidertype',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" disabled value="">
                                    Choose Slider Type
                                </option>
                                {
                                    ["banner", "head_banner", "side_banner", "certificate_banner", "popular_banner"].map((e, i) => (
                                        <option key={i} aria-label="None" value={e}>
                                            {e.replaceAll("_", " ").toUpperCase()}
                                        </option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <Select
                                native
                                value={appereancData.redirect_type}
                                onChange={e => { setAppereanceData({ ...appereancData, redirect_type: e.target.value }) }}
                                label="Select Redirect Type"
                                inputProps={{
                                    name: 'redirect',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" disabled value="">
                                    Choose Redirect Type
                                </option>
                                {
                                    ["products", "category"].map((e, i) => (
                                        <option key={i} aria-label="None" value={e}>
                                            {e.toUpperCase()}
                                        </option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <Select
                                native
                                value={appereancData.devices}
                                onChange={e => { setAppereanceData({ ...appereancData, device_type: e.target.value }) }}
                                label="Select Devices"
                                inputProps={{
                                    name: 'redirect',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" disabled value="">
                                    Choose Device
                                </option>
                                <option aria-label="None" value="desktop">
                                    Desktop
                                </option>
                                <option aria-label="None" value="mobile">
                                    Mobile
                                </option>

                            </Select>
                            {
                                appereancData.slider_type === "banner" && appereancData.device_type === "mobile" ? (
                                    <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                                        <Select
                                            native
                                            value={appereancData.link_to_banner}
                                            onChange={e => { setAppereanceData({ ...appereancData, link_to_banner: e.target.value }) }}
                                            label="Select slider to Link"
                                            inputProps={{
                                                name: 'redirect',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                            <option aria-label="None" disabled value="">
                                                Choose Which Slider With Link
                                            </option>
                                            {
                                                functional.dataRows.map((x) => (
                                                    <option key={x.sliderid} aria-label="None" value={x.sliderid}>
                                                        {x.title}
                                                    </option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                ) : null
                            }
                        </FormControl>
                        <FormControl className="mt-4" style={{ width: '100%' }} variant="outlined">
                            <TextField id="redirect_to" value={appereancData.redirect} onChange={(e) => { setAppereanceData({ ...appereancData, redirect: e.target.value }) }} variant="outlined" placeholder="Redirect To" />
                        </FormControl>
                        <FormControl className="mt-3 w-100">
                            <Button variant="outlined" className="w-100" color="primary" onClick={SaveAppereance} >Save</Button>
                        </FormControl>
                    </Box>
                </div>
            </div>
        </div>
    )
}