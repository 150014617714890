import { Box, Typography, Button } from '@material-ui/core'
import React, { useRef } from 'react'
import NavBar from '../../widget/NavBar'
import { Editor } from '@tinymce/tinymce-react';
import { ApiHelper as ApiHelperc } from '../../helpers';
import { useEffect, useState } from 'react';
import ErrorBox from '../../functions/ErrorBox';
export default function PrivacyPolicy() {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const editorRef = useRef(null);
    const ApiHelper = new ApiHelperc()
    const [privacypolicy, setPrivacyPolicy] = useState("")

    const aboutOnClick = async () => {
        const create = {
            _key: "privacypolicy",
            _value: editorRef.current.getContent()
        }
        if (create._value === "" || create._value === "undefined") {
            return setresponse_data({ ...response_data, status: "Validation Error", message: `Chack your type box and Re-write a valid content in type box`, OC: true })
        }
        const response = await ApiHelper.createAbout(create)
        if (response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: `Privet policy successfully saved`, OC: true })
        }
        return
    }

    async function fetchAbout() {
        const result = await ApiHelper.fetchInformation("privacypolicy")
        setPrivacyPolicy(result?._value)
        return
    }
    useEffect(() => {
        fetchAbout()
        return () => {
            setPrivacyPolicy("");
        };
        // eslint-disable-next-line
    }, [])
    document.title = `Privacy Policy`
    return (
        <div>
            <NavBar />
            <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
            <div className="text-start small-d">
                <Box mx={4} my={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: '1' }}>
                        <Typography variant="h5">
                            PrivacyPolicy Arayurveda
                        </Typography>
                        <Typography variant="subtitle1">
                            Give PrivacyPolicy for the Arayurveda
                        </Typography>
                    </div>
                    <div>
                        <Button variant="outlined" className="ms-3" color="primary" onClick={aboutOnClick} >Publish</Button>
                    </div>
                </Box>
                <hr />
            <Box mx={4} my={2} style={{ display: 'flex' }}>
                <div className="mt-4  p-0 col-12">
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={privacypolicy ?? ""}
                        apiKey="0br1siz57qb0y7dwnxtzccahui7x0el1mj2ygoziavfnzohu"
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: ['print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | preview save print | insertfile image media template link anchor code codesample | ltr rtl',
                            content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                            images_upload_handler: async (blobInfo, success, failure, _) => {
                                const file = blobInfo.blob()
                                const tinyMcResult = await ApiHelper.uploadImage({ image: file })
                                if (tinyMcResult !== "") {
                                    success(tinyMcResult.data[0].uri)
                                } else {
                                    failure('Something Went Wrong')
                                }
                            }
                        }}
                    />
                </div>
            </Box>
            </div>
        </div>
    )
}
