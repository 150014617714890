import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import AppereanceForm from "../../functions/ApperiensForm";
import FormBasedDialog from '../../functions/FormBasedDialog';
import { ApiHelper as ApiHelperc } from "../../helpers";
import NavBar from "../../widget/NavBar";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import EditAppereance from "../../functions/EditAppereance";

export default function Appereance() {
    const [AddAppereance, setAddAppereance] = useState(false)
    const [EditSAppereance, setEditSAppereance] = useState(false)
    // const [id, setid] = useState({
    //     id: "",
    //     device: "",
    //     type: "",
    //     index: "",
    //     perform: ""
    // });
    const [editdata, seteditdata] = useState()
    const [dataRows, setDataRows] = useState([])
    const ApiHelper = new ApiHelperc()

    const dltapp = async (id) => {
        const response = await ApiHelper.deleteAppereance(id)
        if (response.status === "success") {
            fetchAppereance()
        }
    }
    const Editapp = (data) => {
        seteditdata(data)
        setEditSAppereance(true)
    }

    const upindex = async (data) => {
        const postdata = {
            sliderid: data.sliderid,
            slider_type: data.slider_type,
            device_type: data.device_type,
            position: data.position,
            perform: "up"
        }
        const response = await ApiHelper.apperiensindexUD(postdata)
        if (response.status === "success") {
            fetchAppereance()
        }
    }
    const downindex = async (data) => {
        const postdata = {
            sliderid: data.sliderid,
            slider_type: data.slider_type,
            device_type: data.device_type,
            position: data.position,
            perform: "down"
        }
        const response = await ApiHelper.apperiensindexUD(postdata)
        if (response.status === "success") {
            fetchAppereance()
        }
    }
    const columns = [
        { field: 'image', headerName: 'Image', width: 150, renderCell: (image) => (<img alt={image.value} src={image.value} className="img-fluid" height={100} width={100} />) },
        {
            field: "mobile_image", headerName: "Mobile Image", width:170, renderCell: (image) => {
                if(image.value !== undefined && image.value !== null &&  image.value !== ""){
                    return ( <img src={image.value} alt={image.value} className="img-fluid" height={100} width={100} />  )
                }else {
                    return (<span>N/A</span> )
                }
            }
        },
        { field: 'title', headerName: 'Name', width: 250 },
        { field: 'slider_type', headerName: 'Type', width: 140, renderCell: (type) => type.value.replaceAll("_", " ").toUpperCase() },
        // { field: 'device_type', headerName: 'Device', width: 140 },
        { field: 'position', headerName: 'Position', width: 130, editable: true, type: 'number' },
        { field: 'redirect', headerName: 'Redirect', width: 140, editable: true },
        {
            field: 'sliderid', headerName: 'Action', width: 200, renderCell: (cell) => {
                return (
                    <div className="d-flex justify-content-center">
                        <IconButton color="secondary" onClick={(e) => dltapp(cell.value)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={(e) => Editapp(cell.row)}>
                            <EditIcon />
                        </IconButton>
                        <div>
                            <IconButton color="primary" onClick={(e) => upindex(cell.row)}>
                                <ExpandLessIcon />
                            </IconButton>
                            <IconButton color="primary" onClick={(e) => downindex(cell.row)}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>

                    </div>
                )
            }
        },
    ];
    document.title = `Appereance`
    const fetchAppereance = async () => {
        const response = await ApiHelper.fetchAppereance()
        setDataRows([...response.data])
    }

    const AddAppreance = () => {
        setAddAppereance(true)
    }
    useEffect(() => {
        fetchAppereance()
        return () => {
            setDataRows([])
        }
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <NavBar />

            <div className="text-start small-d">
                <Box mx={4} my={2} display="flex" alignItems="center" className="justify-content-between">
                    <div>
                        <Typography variant="h5">
                            Add Home Appereance
                        </Typography>
                        <Typography variant="subtitle1">
                            Add new Appereance into the system.
                        </Typography>
                    </div>
                    <Button variant="outlined" style={{width:"8rem"}} onClick={AddAppreance} color="secondary">
                        Add New
                    </Button>
                </Box>
                <hr />
                <Box m={4}>
                    <DataGrid autoHeight={true} rows={dataRows} getRowId={e => { return e.id }} columns={columns} pageSize={10} />
                </Box>
            </div>
            <FormBasedDialog
                status={{ state: setAddAppereance, isOpen: AddAppereance }}
                title={<><Typography variant="h6">Add Appereacne</Typography><Typography variant="subtitle2"></Typography></>}
                component={<AppereanceForm functional={{ state: setAddAppereance, onSuccess: fetchAppereance, dataRows: dataRows && dataRows }} />}
            />
            <FormBasedDialog
                status={{ state: setEditSAppereance, isOpen: EditSAppereance }}
                title={<><Typography variant="h6">Edit Appereacne</Typography><Typography variant="subtitle2"></Typography></>}
                component={<EditAppereance functional={{ state: setEditSAppereance, onSuccess: fetchAppereance, editdata: editdata, setEditData: seteditdata }} />}
            />
        </div>
    )
}