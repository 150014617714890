import { Box, Divider, FormControl, FormGroup, TextField, Button, Select ,MenuItem } from '@material-ui/core'
import React, { useState } from 'react'
import ErrorBox from '../../functions/ErrorBox'
import { ApiHelper as ApiHelperC } from '../../helpers'

export default function EditReviews(props) {
    const [response_data, setresponse_data] = useState({
        status: "",
        message: "",
        OC: false,
        redirect_to: "",
    })
    const ApiHelper = new ApiHelperC()
    const { functional } = props
    const setEditData = functional.setEditData
    const preveusdata = functional && functional.previasdata && functional.previasdata
    const updateHandler = async () => {
        let data = { ...preveusdata }
        const response = await ApiHelper.UpdateReviews(preveusdata.id, data)
        setEditData({ ...preveusdata })
        if (response.status && typeof response.status !== "undefined" && response.status === "success") {
            setresponse_data({ ...response_data, status: response.status, message: `Review # ${data.id} updated `, OC: true })
            if (typeof functional !== "undefined" && typeof functional === "object" && Object.keys(functional).length > 0) {
                functional.onSuccess()
                functional.state(false)
            }
        }
    }
    return (
        <div>
            <FormGroup>
                <ErrorBox setresponse_data={setresponse_data} response_data={response_data}></ErrorBox>
                <Divider />
                <div className="row">
                    <div className="col-12">
                        <FormControl fullWidth variant="outlined" className="mt-3">
                            <TextField type="text" name="review" fullWidth multiline value={preveusdata && preveusdata.message ? preveusdata.message : ""} rows={4} onChange={(e) => setEditData({ ...preveusdata, message: e.target.value })} rowsMax={5} id="review" variant="outlined" placeholder="Edit Review"></TextField>
                        </FormControl>
                    </div>
                    <div className="col-12">

                        <FormControl fullWidth variant="outlined" className="mt-3">
                            <Select className="mt-3" value={preveusdata && preveusdata.rating ? preveusdata.rating : ""} name="rating"
                                onChange={(e) => setEditData({ ...preveusdata, rating: e.target.value })}
                                displayEmpty inputProps={{
                                    name: 'rating',
                                    id: 'outlined-age-native-simple',
                                }} id="rating"
                            >
                                <MenuItem disabled={true} value="">
                                    <em>--Select-Rating--</em>
                                </MenuItem>
                                <MenuItem value="1">
                                    1
                                </MenuItem>
                                <MenuItem value="2">
                                    2
                                </MenuItem>
                                <MenuItem value="3">
                                    3
                                </MenuItem>
                                <MenuItem value="4">
                                    4
                                </MenuItem>
                                <MenuItem value="5">
                                    5
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

            </FormGroup>
            <Box display="flex" mt={3} justifyContent="end">
                <Button color="primary" onClick={(e) => updateHandler()}>
                    Update
                </Button>
            </Box>
        </div>
    )
}
