import React, { useEffect, useState } from 'react'
import { Box, Typography, Divider, Button, IconButton } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid';
import NavBar from '../../widget/NavBar';
import { ApiHelper as ApiHelperC } from '../../helpers';
import moment from 'moment'
import PATH from '../../helpers/Routes';
import { EditOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
export default function PripaidSuccess() {
    const ApiHelper = new ApiHelperC()
    const [isLoading, setIsLoading] = useState(true)
    const [dataRows, setDataRows] = useState([])
    const columns = [
        { field: 'orderid', headerName: 'OrderID', width: 150, },
        { field: 'full_name', headerName: 'Customer', width: 200 },
        {
            field: 'raz_order_id', headerName: 'Razorpay ID', width: 270, renderCell: (cell) => {
                if (cell.value === null) {
                    return "N/A"
                } else {
                    return cell.value
                }
            }
        },
        {
            field: 'raz_payment_id', headerName: 'Payment ID', width: 270, renderCell: (cell) => {
                if (cell.value === null) {
                    return "N/A"
                } else {
                    return cell.value
                }
            }
        },
        { field: 'total_price', headerName: 'Total Price', width: 150 },
        { field: 'type', headerName: 'Type', width: 150 },
        {
            field: 'payment_status', headerName: 'Payment Staus', width: 150, renderCell: (cell) => {
                if (cell.value === "pending") {
                    return <Typography variant="caption" color="secondary" className="fw-bold text-uppercase">{cell.value}</Typography>
                } else if (cell.value === "cancled") {
                    return <Typography variant="caption" color="error" className="fw-bold text-uppercase">{cell.value}</Typography>
                } else if (cell.value === "success") {
                    return <Typography variant="caption" color="primary" className="fw-bold text-uppercase">{cell.value}</Typography>
                }
            }
        },
        {
            field: 'created_at', headerName: 'Order AT', type: 'dateTime', width: 250, renderCell: (cell) => {
                return moment(cell.value).format("YYYY-MM-DD hh:mm:ss A")
            }
        },
        {
            field: 'id', headerName: 'Action', width: 250, renderCell: (params) => (
                <Button variant="contained" color="secondary">Raise issue</Button>
            )
        },
        {
            field: "", headerName: "Action", flex: 1,
            renderCell: (params) => (
                <Link to={`/order/edit/${params.row.orderid}`}>
                    <IconButton color="primary" onClick={() => window.open(`/#${PATH.order.edit.replace(":id", params.row.orderid)}`)}>
                        <EditOutlined />
                    </IconButton>
                </Link>
            )
        },

    ];
    // const isueHandler = () => {
    //     console.log(params.value)
    // }
    async function fetchOrderDetails() {
        const result = await ApiHelper.fetchOrderDetails("online")
        // console.log(result)
        if (result.status === "success") {
            setIsLoading(false)
            //     console.log(result.data)
            setDataRows([...result.data])
        } else {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchOrderDetails()
        return () => {
            setIsLoading(true);
            setDataRows([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    document.title = `Prepaid Success Orders`
    return (
        <div>
            <NavBar />
            <div className="text-start small-d">
                <Box mx={4} my={2}>
                    <Typography variant="h5">
                        Prepaid Order Detail
                    </Typography>
                    <Typography variant="subtitle1">
                        Prepaid Order Detail Of Your Customer
                    </Typography>
                </Box>
                <hr />
                <Divider />
                <Box m={4}>
                    <DataGrid loading={isLoading} autoHeight={true} rows={dataRows} getRowId={e => { return e.orderid }} columns={columns} pageSize={10} />
                </Box>
            </div>
        </div>
    )
}
