import {
    Box,
  FormControl,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
// import { ApiHelper as ApiHelperC } from "../helpers";


export default function EditfaqModel(props) {
    // const ApiHelper = new ApiHelperC();
    const {editState,setEditState,setOpenEditModel} = props
    const Submit_faq = async() => {
        let data = {...editState}
        console.log(data)
        setOpenEditModel(false)
        // const response = await ApiHelper.SaveEditfaq(editState.faqid,data)

    }
  return (
    <div>
      <FormControl
        className="mb-2"
        style={{ width: "100%" }}
        variant="outlined"
      >
        <Typography variant="subtitle1" className="text-muted fw-bold">
          Question
        </Typography>
        <TextField
          id="Efaq_qus"
          fullWidth
          multiline={true}
          rows={5}
          value={editState && editState.faq_qus && editState.faq_qus}
          onChange={(e) => setEditState(oldstate => {return{...oldstate,faq_qus:e.target.value}})}
          variant="outlined"
          placeholder="Question"
        />
      </FormControl>
      <FormControl
        className="mb-2"
        style={{ width: "100%" }}
        variant="outlined"
      >
        <Typography variant="subtitle1" className="text-muted fw-bold">
          Answer
        </Typography>
        <TextField
          id="Efaq_ans"
          fullWidth
          multiline={true}
          rows={5}
          value={editState && editState.faq_ans && editState.faq_ans}
          onChange={(e) => setEditState(oldstate => {return{...oldstate,faq_ans:e.target.value}})}
          variant="outlined"
          placeholder="Answer"
        />
      </FormControl>
      <FormControl component="fieldset" className="mt-4">
        <Typography variant="subtitle1" className="text-muted fw-bold">
          Publish Status
        </Typography>
        <RadioGroup
          id="readio_status"
          aria-label="publish_status"
          name="publish_status"
          value={`${editState && editState.status && editState.status}`}
          onChange={(e) => setEditState(oldstate => {return{...oldstate,status:e.target.value}})}
        >
          <FormControlLabel
            id={"stetus_yes"}
            value={"1"}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            id={"stetus_no"}
            value={"0"}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
      <Box display="flex" mt={3} justifyContent="end">
        <Button onClick={Submit_faq} color="primary">
          Save
        </Button>
      </Box>
    </div>
  );
}
