import { Switch, HashRouter, Route } from 'react-router-dom'
import { Dashboard, Login, AddPost, ViewPost, Category, AboutWdiget, SettingWidget, NewProduct, AllProducts, ReviewProduct, Appereance, PrivacyPolicy, ShippingPolicy, TermsCondition, RetrunRefund, SocailShare, Faqs, TrendingProduct, Vieworder, AddOrder, CodSuccess, PripaidSuccess, Dispatch, Verify, Rejected, Working, Editproduct, Productmedia, AddAgent, ViewAgent, Editorder, Pending, Cancel, EditPost, Inquiry } from './pages'
import { ProtectedRouter, Routes as PATH } from './helpers'
import "./App.css"

function App() {
  return (
    <div>
      <HashRouter>
        <Switch>
          <Route exact path={PATH.login} component={Login} />
          <ProtectedRouter exact path={PATH.dashboard} component={Dashboard} />
          <ProtectedRouter exact path={PATH.inquiry} component={Inquiry} />
          <ProtectedRouter exact path={PATH.product.add} component={NewProduct} />
          <ProtectedRouter exact path={PATH.product.review} component={ReviewProduct} />
          <ProtectedRouter exact path={PATH.order.add} component={AddOrder} />
          <ProtectedRouter exact path={PATH.product.view} component={AllProducts} />
          <ProtectedRouter exact path={PATH.product.edit} component={Editproduct} />
          <ProtectedRouter exact path={PATH.product.productmedia} component={Productmedia} />
          <ProtectedRouter exact path={PATH.product.trending} component={TrendingProduct} />
          <ProtectedRouter exact path={PATH.order.view} component={Vieworder} />
          <ProtectedRouter exact path={PATH.order.edit} component={Editorder} />
          <ProtectedRouter exact path={PATH.order.pending} component={Pending} />
          <ProtectedRouter exact path={PATH.order.cancel} component={Cancel} />
          <ProtectedRouter exact path={PATH.order.codsuccess} component={CodSuccess} />
          <ProtectedRouter exact path={PATH.order.pripaidsuccess} component={PripaidSuccess} />
          <ProtectedRouter exact path={PATH.order.dispatch} component={Dispatch} />
          <ProtectedRouter exact path={PATH.order.verify} component={Verify} />
          <ProtectedRouter exact path={PATH.order.rejected} component={Rejected} />
          <ProtectedRouter exact path={PATH.order.working} component={Working} />
          <ProtectedRouter exact path={PATH.post.add} component={AddPost} />
          <ProtectedRouter exact path={PATH.post.view} component={ViewPost} />
          <ProtectedRouter exact path={PATH.post.edit} component={EditPost} />
          <ProtectedRouter exact path={PATH.category} component={Category} />
          <ProtectedRouter exact path={PATH.setting} component={SettingWidget} />
          <ProtectedRouter exact path={PATH.about} component={AboutWdiget} />
          <ProtectedRouter exact path={PATH.privacy_policy} component={PrivacyPolicy} />
          <ProtectedRouter exact path={PATH.shipping_policy} component={ShippingPolicy} />
          <ProtectedRouter exact path={PATH.terms_condition} component={TermsCondition} />
          <ProtectedRouter exact path={PATH.return_refund} component={RetrunRefund} />
          <ProtectedRouter exact path={PATH.faqs} component={Faqs} />
          <ProtectedRouter exact path={PATH.appereance} component={Appereance} />
          <ProtectedRouter exact path={PATH.share} component={SocailShare} />
          <ProtectedRouter exact path={PATH.users.addUser} component={AddAgent} />
          <ProtectedRouter exact path={PATH.users.viewUser} component={ViewAgent} />
          <Route path='*' exact component={Login} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;